import { Avatar, Button, Card } from "antd";
import { UserOutlined, EditOutlined } from '@ant-design/icons';
import React from "react";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { getProfile } from "../../data";
import { useState } from "react";
const { Meta } = Card;

export default function Profile() {
    const [profile, setProfile] = useState([])
    const [forceRefresh, setForceRefresh] = useState(true)

    let history = useHistory()

    const initialize = async () => {
        const data = await getProfile(forceRefresh)
        setProfile(data)
        setForceRefresh(false)
    }

    useEffect(() => {
        initialize()
    }, [])
    return (
        <>
            <Card style={{ width: 370 }}
                cover={
                    <>
                        <center>
                            <Avatar size={170} src="https://avatars.dicebear.com/api/bottts/your-custom-seed.svg" shape={'square'} icon={<UserOutlined />} />
                        </center>
                    </>
                }
                actions={[
                    <Button icon={<EditOutlined key="edit" />} type="primary"
                        onClick={() => history.push({ pathname: '/profile/change-password/form/' + profile.id })}>
                        Change Password
                    </Button>,
                    <Button icon={<EditOutlined key="edit" />} type="primary"
                        onClick={() => history.push({ pathname: '/profile/form/' + profile.id })}>
                        Edit Profile
                    </Button>,
                ]}
            >
                <Meta
                    avatar={<Avatar src="https://avatars.dicebear.com/api/bottts/your-custom-seed.svg" />}
                    title="Profile Name"
                    description={profile.name}
                />
            </Card>
        </>
    )
}