import { ajaxService, loadState, urlService } from "../services";
import { loadResource, Response } from "../utility";

export async function getProfile(refresh = false) {
    ajaxService.removeDependency()
    if (refresh) {
        return await loadResource(urlService.profile, 'profile');
    } else {
        let profile = await loadState('profile');

        if (profile == null) {
            return await loadResource(urlService.profile, 'profile');
        } else {
            return Response(profile);
        }
    }
}

export async function updateProfile(id, data) {
    ajaxService.removeDependency()
    return ajaxService.put(urlService.profile + '/' + id, data).then(response => {
        return Response(response.data);
    })
}


export async function getUser() {
    ajaxService.removeDependency()
    return ajaxService.get(urlService.user).then(response => {
        return response.data;
    })
}
