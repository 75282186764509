// if (_DEV_) {
// }

import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router } from "react-router-dom";
import Navigation from './navigation';
import soundAlert from './layout/sound_alert';

// import('./reactotron-config').then(() => {  console.log('Reactotron Configured')});

export default function App() {

  const token = localStorage.getItem('token');

  useEffect(() => {
    if (token != null) {
      soundAlert()
    }
  }, []);

  return (
    <Router>
      <Navigation />
    </Router>
  );
}