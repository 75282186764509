import { Button, Col, Form, Input, Modal, Row, Select, Space } from "antd"
import { useEffect } from "react"

const { Option } = Select

const DineInModal = ({ visible, toggle, setFormData, orderInfo, buttonType, waiters, tables }) => {

    const [orderForm] = Form.useForm()

    useEffect(() => {

        if (buttonType === 1 || buttonType === 2 || buttonType === 3)
            orderForm.resetFields()

    }, [orderInfo])

    const onFinish = (values) => {
        const json = JSON.stringify(values)
        setFormData({ json, orderType: 0 })
        toggle(false)
    }

    return (
        <Modal
            width="60%"
            title="Dine In"
            centered
            open={visible}
            onCancel={() => toggle(false)}
            footer={[
                <Space key="space">
                    <Button type="primary" key="sbmit" onClick={orderForm.submit}> Submit </Button>
                    <Button key="back" onClick={() => toggle(false)}>
                        Close
                    </Button>
                </Space>
            ]} >

            <Form form={orderForm} onFinish={onFinish}>
                <Row gutter={16}>

                    <Col span={12}>
                        <Form.Item name="total_guest" label="Total Guests" labelCol={{ span: 24 }} initialValue={orderInfo.total_guest}
                            rules={[
                                {
                                    required: true,
                                    message: 'Field is required'
                                }
                            ]}>
                            <Input type="number" placeholder="Total Guests"
                                key={orderInfo.total_guest} defaultValue={orderInfo.total_guest}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item name="table" label="Table" labelCol={{ span: 24 }}
                            initialValue={orderInfo.table}
                            rules={[
                                {
                                    required: true,
                                    message: 'Field is required'
                                }
                            ]}>
                            <Select
                                key={orderInfo.table}
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Select Table"
                            >
                                {tables.map(({ id, name }) => (
                                    <Option key={id} value={id}>
                                        {name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item name="waiter" label="Waiter" labelCol={{ span: 24 }} initialValue={orderInfo.waiter}
                            rules={[
                                {
                                    required: true,
                                    message: 'Field is required'
                                }
                            ]}>
                            <Select
                                key={orderInfo.waiter}
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Select Waiter"
                            >
                                {waiters.map(({ id, name }) => (
                                    <Option key={id} value={id}>
                                        {name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>
            </Form>
        </Modal >
    )
}

export default DineInModal