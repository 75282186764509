import { Button, Modal } from "antd"

const ResumedDiscardModal = ({ type, visible, toggle, discardCart, children }) => {
    return (
        <Modal
            width={type == "discard" ? "40%" : "60%"}
            title={"Resume Cart"}
            centered
            open={visible}
            onCancel={() => toggle(false)}
            footer={[
                type == 'discard' &&
                <Button key="save" type="primary" onClick={discardCart}>
                    Confirm
                </Button>,
                <Button key="back"
                    onClick={() => toggle(false)}
                >
                    Close
                </Button>
            ]}>
            {children}
        </Modal>
    )
}

export default ResumedDiscardModal
