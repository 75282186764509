import { Button, Col, Empty, Modal, Row, Space, Tag } from "antd"
import moment from "moment";
import { useState, useEffect } from "react";
import { cashRegisterOrder } from "../../../data";

const PreviousInvoiceModal = ({ visible, toggle, printModal, setOrder, setItems, loadScreen }) => {

    const [orders, setOrders] = useState([])

    const initialize = async () => {
        let id = localStorage.getItem('cashRegister_id')
        const response = await cashRegisterOrder(id)

        if (response) {
            if (response[0]) {
                setOrders(response[0].order)
            }
        }
    }

    const print = (id) => {
        let order = orders.find(i => i.id == id)
        setOrder(order)
        setItems(order.order_items)
        printModal(true)
        toggle(false)
    }

    useEffect(() => {
        initialize()
    }, [loadScreen])

    return (
        <Modal
            width="90%"
            title="Recent Orders"
            centered
            open={visible}
            onCancel={() => toggle(false)}
            footer={[
                <Button key="back"
                    onClick={() => toggle(false)}
                >
                    Close
                </Button>
            ]}>
            <Row style={{ backgroundColor: '#f7f3f2', padding: '15px 20px' }}>
                <Col span={3}>Order Number</Col>
                <Col span={4}>Placed On</Col>
                <Col span={4}>Subtotal</Col>
                <Col span={3}>Discount</Col>
                <Col span={4}>Total</Col>
                <Col span={3}>Status</Col>
                <Col span={2} style={{ textAlign: "center" }}>Action</Col>
            </Row>
            {
                orders.map((order, index) => (
                    <Row gutter={16} style={{ padding: '15px 20px' }} key={'row-' + index}>
                        <Col span={3}>{order.order_number}</Col>
                        <Col span={4}>{moment(order.date).format('DD-MMM-y')}</Col>
                        <Col span={4}>{order.subtotal}</Col>
                        <Col span={3}>{order.discount}</Col>
                        <Col span={3}>{order.total}</Col>
                        <Col span={4} >
                            <Tag color={'geekblue'} style={{ marginLeft: 24 }} key={index}>
                                {order.status}
                            </Tag>
                        </Col>
                        <Col span={2} style={{ textAlign: 'left' }}>
                            <Space>
                                <Button type="primary" onClick={() => print(order.id)}>Print</Button>
                            </Space>
                        </Col>
                    </Row>
                ))
            }
            {orders.length == 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        </Modal>

    )
}

export default PreviousInvoiceModal