import { getAllOrder, getOrderItems, truncateOrderItems, truncateOrders } from "./sql-service";
import { PlaceOrder } from "../data";

const sync = async (db, setRefreshSync) => {
    const orders = await new Promise(async (resolve, reject) => {
        let orders = await getAllOrder(db)
        resolve(orders);
    });

    if (orders.length > 0) {
        setRefreshSync(true)
        for (const order of orders) {
            const items = await new Promise(async (resolve, reject) => {
                let items = await getOrderItems(db, order.id)
                resolve(items);
            });

            order.order_items = items;
            await PlaceOrder(order);
        }

        truncateOrders(db)
        truncateOrderItems(db)

        setRefreshSync(false)
        window.location.reload(true);
    }
    setRefreshSync(false);
};
export default sync