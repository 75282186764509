import { ajaxService, loadState, urlService } from "../services";
import { loadResource, Response } from "../utility";

const setDependency = () => { ajaxService.setDependency() }

const branchDependency = () => { return ajaxService.getBranchDependencyUrl(urlService.cashRegister) }

export async function getCashRegisters(refresh = false) {
    setDependency()
    if (refresh) {
        return await loadResource(branchDependency(), 'cashRegister');
    } else {
        let cashRegister = await loadState('cashRegister');

        if (cashRegister == null) {
            return await loadResource(branchDependency(), 'cashRegister');
        } else {
            return Response(cashRegister);
        }
    }
}

export async function saveCashRegister(data) {
    setDependency()
    return ajaxService.post(branchDependency(), data).then(response => {
        return Response(response.data);
    })
}

export async function getCashRegisterEntries(id) {
    setDependency()
    return ajaxService.get(branchDependency() + '/' + id).then(response => {
        return Response(response.data);
    })
}

export async function updateCashRegister(id, data) {
    setDependency()
    return ajaxService.put(branchDependency() + '/' + id, data).then(response => {
        return Response(response.data);
    })
}