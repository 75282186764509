const ControlSettings = {
    BRANCH_CATALOG: 'branch-catalog',
    TIMEZONE: 'time-zone',
    CURRENCY: 'currency',
    ENABLE_FBR_SETTINGS: 'enable-fbr-settings',
    SRB_SETTINGS: 'srb-settings',
    ENABLE_POS: 'enable-pos',
    ENABLE_PICKUP: 'enable-pickup',
    BYKEA_DELIVERY: 'bykea-delivery',
    PUSH_NOTIFICATION: 'push-notification-settings',
    USER_MANAGEMENT: 'user-management',
    MANAGE_INVENTORY: 'manage-inventory',
    MANAGE_CUSTOMERS: 'manage-customers',
    ENABLE_WEBSITE: 'enable-website',
    ENABLE_MOBILE: 'enable-mobile',
    WHATSAPP_MESSAGING: 'whatsapp-messaging',
    ENABLE_DELIVERY: 'enable-delivery',
    TRAX: 'trax',
    ENABLE_DINEIN: 'enable-dine-in',
    COMBO_INVENTORY: 'combo-inventory',
    OFFER_BRANCH_CONTROL: 'offer-control-in-branch',
    RECEIPT_FORMAT: 'receipt-format',
};

export default ControlSettings;
