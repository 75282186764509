import React, { useState } from "react";
import { Button, Card, Col, Divider, Form, Input, Modal, Radio, Row, Select, Space, Table, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { getCashRegisterEntries, updateCashRegister } from "../../data";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import useWebSocket from "../../utility/use-websocket";
import { PAGINATION_OPTIONS } from "../../constants";

const { Option } = Select
const { Title, Text } = Typography

function CashRegisterEntry() {

    const { socketHandler } = useWebSocket()

    const [showModal, setShowModal] = useState(false)
    const [actionType, setActionType] = useState('')
    const [radioValue, setRadioValue] = useState(1)
    const [dataSource, setDataSource] = useState([])
    const [customers, setCustomers] = useState([])
    const [suppliers, setSuppliers] = useState([])
    const [estimateClosing, setEstimateClosing] = useState(0)

    const [form] = Form.useForm();
    let { id } = useParams()
    let history = useHistory()
    const branch_id = localStorage.getItem('posBranchId')

    const columns = [
        {
            title: 'Date',
            dataIndex: 'created_on',
            key: 'created_on',
            width: "250px",
            render: (text, record) => (<Text>{moment(record.created_on).format('DD-MMM-yyy hh:mm a')}</Text>)
        },
        {
            title: 'Amount',
            // align:'right',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
    ]

    const onFinish = async (values) => {

        const response = await updateCashRegister(id, values)
        if (response) {
            setShowModal(false)
            form.resetFields();
            initialize();
            setRadioValue(1)
            // notification.open({ message: 'Opening amount has been added successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
        }
        else {
            // notification.open({ message: 'Unable to add opening amount', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }

    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const initialize = async () => {

        const data = await getCashRegisterEntries(id);
        if (data) {
            if (data[0].type === 'Closing') {
                localStorage.removeItem('cashRegister_id');
                history.push({ pathname: '/cash-register' });
            }

            const c_amount = data.reduce((acc, cur) => {
                if (cur.addition === 0 && cur.type !== 'Closing') {
                    return acc - parseInt(cur.amount);
                } else if (cur.addition === 1 && cur.type !== 'Closing') {
                    return acc + parseInt(cur.amount);
                }
                return acc;
            }, 0);

            setDataSource(data);
            setEstimateClosing(c_amount);
            form.setFieldsValue({ closing_amount: c_amount });
        }
    }

    useEffect(() => {
        initialize()
        // Customers
        socketHandler('customers', null, (response) => {
            setCustomers(response)
        });
        // suppliers
        socketHandler('suppliers', { branch_id: branch_id }, (response) => {
            setSuppliers(response)
        });

    }, [])

    const openModal = (type) => {
        setActionType(type)
        setShowModal(true)
    }

    const onChange = (e) => {
        let diff = estimateClosing - e.target.value;
        form.setFieldsValue({ difference: diff })
    }

    const renderModalFields = () => {
        if (actionType != "closeRegister") {
            return (
                <>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label="Enter Description"
                                name='description'
                                labelCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Description is required',
                                    },
                                ]}>
                                <TextArea rows={1}></TextArea>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="Enter Amount"
                                name='amount'
                                labelCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Amount is required',
                                    },
                                ]}>
                                <Input type="number" min={0} step={0.01} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item name="type" initialValue={actionType == "addCash" ? "addIncome" : "removeIncome"}
                                rules={[
                                    {
                                        required: true,
                                        message: 'required',
                                    },
                                ]}
                            >
                                <Radio.Group onChange={(e) => { setRadioValue(e.target.value); form.resetFields(['supplier_id', 'customer_id']) }}>
                                    <Row>
                                        <Col span={18}>
                                            <Radio value={actionType == "addCash" ? "addIncome" : "removeIncome"}>Other Income</Radio>
                                        </Col>
                                        <Col span={5}>
                                            <Radio value={actionType == "addCash" ? "addExpense" : "removeExpense"}>Expense</Radio>
                                        </Col>
                                    </Row>

                                    <Divider style={{ width: '140%' }} />

                                    <Row style={{ width: '140%' }}>
                                        <Col span={13}>
                                            <Radio value={actionType == "addCash" ? "supplierAddCash" : "supplierRemoveCash"}> Supplier  {actionType == "addCash" ? "Receipt" : "Payment"}</Radio>
                                        </Col>
                                        <Col span={11}>
                                            <Radio value={actionType == "addCash" ? "customerAddCash" : "customerRemoveCash"}>Customer  {actionType == "addCash" ? "Receipt" : "Payment"}</Radio>
                                        </Col>
                                    </Row>
                                </Radio.Group>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item name="supplier_id"
                                rules={[
                                    radioValue == (actionType == "addCash" ? "supplierAddCash" : "supplierRemoveCash") &&
                                    {
                                        required: true,
                                        message: 'Supplier is required',
                                    }
                                ]}>
                                <Select
                                    allowClear
                                    placeholder="Select Supplier"
                                    optionFilterProp="children"
                                    disabled={radioValue == (actionType == "addCash" ? "supplierAddCash" : "supplierRemoveCash") ? false : true}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {suppliers.map(supplier => {
                                        return <Option value={supplier.id} key={"supplier-" + supplier.id} >{supplier.name}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="customer_id"
                                rules={[
                                    radioValue == (actionType == "addCash" ? "customerAddCash" : "customerRemoveCash") &&
                                    {
                                        required: true,
                                        message: 'Customer is required',
                                    }
                                ]}>
                                <Select
                                    allowClear
                                    placeholder="Select Customer"
                                    optionFilterProp="children"
                                    disabled={radioValue == (actionType == "addCash" ? "customerAddCash" : "customerRemoveCash") ? false : true}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {customers.map(customer => {
                                        return <Option value={customer.id} key={"customer-" + customer.id} >{customer.name}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                    </Row>
                </>
            )
        }
        else {
            return (
                <>
                    <Form.Item
                        label="Estimate Closing Float"
                        name='closing_amount'
                        labelCol={{ span: 24 }}
                        rules={[
                            {
                                required: true,
                                message: 'Estimate Closing Float is required',
                            },
                        ]}>
                        <Input type="number" min={0} step={0.01} readOnly />
                    </Form.Item>
                    <Form.Item
                        label="Enter Amount In Hand"
                        name='amount_in_hand'
                        labelCol={{ span: 24 }}
                        rules={[
                            {
                                required: true,
                                message: 'Enter Amount In Hand is required',
                            },
                        ]}>
                        <Input type="number" key={1} onChange={onChange} min={0} step={0.01} />
                    </Form.Item>
                    <Form.Item
                        label="Difference"
                        name='difference'
                        labelCol={{ span: 24 }}
                        rules={[
                            {
                                required: true,
                                message: 'Difference is required',
                            },
                        ]}
                    >
                        <Input key={2} readOnly />
                    </Form.Item>
                </>
            )
        }
    }

    const renderHeading = () => {
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Title level={3}>Cash Register Entry</Title>
                    <Space>
                        <Button type="primary" onClick={() => openModal('addCash')}>Add Cash</Button>
                        <Button type="primary" danger onClick={() => openModal('removeCash')}>Remove Cash</Button>
                        <Button style={{ background: '#003366', color: '#FFF' }} onClick={() => openModal('closeRegister')}>Close Register</Button>
                    </Space>
                </div>
            </>
        )
    }

    const renderModal = () => (
        <Form
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            form={form}
        >
            <Modal
                width="500px"
                title={actionType == 'addCash' ? 'Add Cash' : (actionType == 'removeCash' ? 'Remove Cash' : 'Close Register')}
                centered
                open={showModal}
                onCancel={() => { setShowModal(false) }}
                footer={[
                    <Button key="save" type="primary" onClick={form.submit}>
                        Save
                    </Button>,
                    <Button key="back" onClick={() => { setShowModal(false) }}>
                        Close
                    </Button>
                ]}>
                {renderModalFields()}
            </Modal>
        </Form>
    )

    return (
        <>
            <Card>
                {renderHeading()}
                <br />
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    pagination={{ ...PAGINATION_OPTIONS }}
                />
            </Card>
            {renderModal()}
        </>
    )

}

export default CashRegisterEntry