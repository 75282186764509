import { Col, Row } from "antd"

const CartItems = ({ children }) => (
    <div style={{ marginTop: 10 }}>
        <Row className="themeBtn" style={{ padding: '15px 15px' }}>
            <Col span={4}>Product</Col>
            <Col span={4} style={{ textAlign: 'center' }}>Quantity</Col>
            <Col span={5} style={{ textAlign: 'center' }}>Per Unit Price</Col>
            <Col span={4} style={{ textAlign: 'center' }}>Discount</Col>
            <Col span={4} style={{ textAlign: 'center' }}>Subtotal</Col>
        </Row>
        <br />
        <div style={{ overflow: 'auto', height: '400px', marginLeft: 7 }}>
            {children}
            <br />
        </div>
    </div>
)

export default CartItems