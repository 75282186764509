import axios from 'axios';// import SessionManager from '../../SessioManager';// import store from '../../redux/store/index';
// import { showLoader, hideLoader } from '../../redux/actions/loaderAction';
// import ToastService from './toastService';
import { CLIENT_ID, CLIENT_SECRET, DOMAIN } from '../constants';

// const domain = process.env.NODE_ENV === 'development' ? LOCAL_API_URL : SERVER_API_URL;
const domain = DOMAIN;

export const GlobalVariable = Object.freeze({
    APP_VERSION: '1.0.0',
    BASE_API_URL: `${domain}/v1/`,
    BASE_IMAGE_URL: domain
});

let dependency;
const segment = 'stores';

class AjaxService {
    removeDependency() {
        dependency = false;
    }
    setDependency() {
        dependency = true;
    }

    getImage(url) {
        return GlobalVariable.BASE_IMAGE_URL + '/' + url;
    }

    getImageUploadUrl() {
        return GlobalVariable.BASE_API_URL + 'api/file/upload';
    }

    getBranchDependencyUrl(path) {
        let brancheId = localStorage.getItem('posBranchId')
        return `branches/${brancheId}/` + path;
    }

    generateUrl(url) {
        if (dependency) {
            const store_id = localStorage.getItem('store_id');
            const path = `${segment}/${store_id}/${url}`;
            return GlobalVariable.BASE_API_URL + 'api/' + path;
        } else {
            return GlobalVariable.BASE_API_URL + 'api/' + url;
        }
    }

    get(url, data) {
        return this.executeRequest(url, data, 'GET');
    }

    post(url, data) {
        return this.executeRequest(url, data, 'POST');
    }
    put(url, data) {
        return this.executeRequest(url, data, 'PUT');
    }
    delete(url, data) {
        return this.executeRequest(url, data, 'DELETE');
    }

    async executeRequest(url, data, type) {
        //store.dispatch(showLoader());

        let headers = {
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        };

        const uri = this.generateUrl(url);

        let options = {
            method: type,
            url: uri,
            headers: headers,
            data: null,
        }

        if (type === 'GET') {
            options.params = data;
        } else if (type === 'POST' || type === 'PUT') {
            options.data = data;
        }


        return axios(options).then(response => {
            //store.dispatch(hideLoader());
            return response;
        }).catch(function (error) {
            /*
            store.dispatch(hideLoader());
            
            if (uri.includes("ogin") || uri.includes("egister")) {
                ToastService.show('Invalid phone or password');
            } else {
                ToastService.show('Network error. Check your internet connection.');
            }
            */
        });
    }

    async getAccessToken(username, password) {

        let data = {
            client_id: CLIENT_ID,
            client_secret: CLIENT_SECRET,
            username: username,
            password: password,
            grant_type: 'password',
            module : 'gpos',
        };

        const uri = domain + '/oauth/token';

        let options = {
            method: 'POST',
            url: uri,
            data: data,
        }

        return axios(options).then(response => {
            //store.dispatch(hideLoader());
            return response;
        }).catch(function (error) {
            //store.dispatch(hideLoader());
            //ToastService.show('Network error. Check your internet connection.');
        });
    }
}

const ajaxService = new AjaxService();

export default ajaxService;