import { useEffect, useState } from "react";
import { Button, Card, List, Typography, Image, message, Spin, Empty, Select } from "antd"
import { useCart } from "react-use-cart";
import { getAllProducts, getProduct, getProductBySKU, updateProduct } from "../../../services/sql-service";
import ComboStockCalculator from "../../../constant/comboStockCalculator";

const { Text } = Typography

const Products = ({ products, branch_id, db, getProductsFromWebSql, loading, children, searchRef }) => {

    const { addItem } = useCart();
    const [options, setOptions] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null);
    const [currency, setCurrency] = useState(null);


    const addToCart = async (item, quantity = 1) => {

        let row = {
            id: item.id,
            name: item.name,
            price: item.price,
            quantity: quantity,
            product_id: item.product_id,
            productCombo: item.productCombo,
            isCombo: item.isCombo,
            stock: item.stock,
            cost_price: item.cost_price,
            unlimited_stock: item.unlimited_stock,
            available_stock : item.available_stock
        }

        await updateProduct(db, { stock: row.stock - 1, price: row.price }, row.product_id, false)

        if (row.productCombo) {
            const hasMismatchedItems = Object.keys(row.productCombo).length > 0 && row.productCombo.some(item => {
                return !products.some(product => item.item_id === product.product_id);
            });

            if (hasMismatchedItems) {
                return message.error('The following products are not assigned to your branch. Please contact the system admin.');
            } else {

                // For Combo
                if (row.isCombo) {

                    localStorage.setItem('comboInCart', true)

                    for (let r in row.productCombo) {
                        let response = await getProduct(db, r.product_id, false)
                        await updateProduct(db, { stock: response.stock - Math.ceil(r.quantity), price: response.price }, r.product_id, false);
                        getProductsFromWebSql()
                    }
                }
            }
        }

        // For Simple
        if (!row.isCombo) {
            let response = await getAllProducts(db)
            const comboProducts = products.filter(i => i.isCombo == 1)
            const possibleStock = ComboStockCalculator(comboProducts, response)

            for (let i in possibleStock) {
                await updateProduct(db, { stock: i.productQuantity, price: i.price }, i.id, false)
            }
            getProductsFromWebSql()
        }

        addItem(row, quantity);
        getProductsFromWebSql()
    }

    useEffect(() => {

        let currency = localStorage.getItem('currency') ;

        const getSelectOptions = async () => {
            let response = await getAllProducts(db)
            
            if (response.length > 0) {
                const options = response.map((item) => ({
                    value: item.sku + ' ' + item.name,
                    label: item.name + ' (' + currency + ' ' + item.price + ')',
                }));
                
                setOptions(options)
                setCurrency(currency)
            }
        }

        setTimeout(() => {
            getSelectOptions()
        }, 1000)

    }, [])

    const handleSelect = async (value) => {

        if (searchRef && searchRef.current) {
            searchRef.current.blur(); // Blur the input to trigger the change event
            setSelectedValue(null); // Clear the selected value in state
            searchRef.current.focus(); // Focus back on the input
        }

        if (value) {
            let parts = value.split(' ')
            let sku = parts[0];

            let items = await getProductBySKU(db, sku);

            if (items.length > 0) {
                let item = items[0];
                if (item.unlimited_stock != 1 && (item.stock <= 0 || !item.stock)) {
                    return message.error('The item is out of stock.');
                };
                addToCart(item, 1);
            }

        }
    }

    return (
        <Card style={{ width: "97%" }}>
            {/* category */}
            {children}

            {/* search Bar */}
            <div style={{ margin: '10px 0px 10px 0px' }}>
                <Select
                    ref={searchRef}
                    showSearch
                    autoFocus
                    allowClear={true}
                    style={{
                        width: '100%',
                    }}
                    value={selectedValue}
                    placeholder="Search by product name or SKU (Ctrl + Shift)"
                    onSelect={handleSelect}
                    options={options}
                />

            </div>

            <div style={{ overflow: 'auto', height: '500px' }}>
                <List
                    locale={{
                        emptyText: loading === true ?
                            <Spin style={{ marginTop: 100 }} spinning={loading} size="large" tip="Please wait..." />
                            :
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />,
                    }}
                    grid={{ gutter: 10, column: products[0]?.image === null ? 2 : 4 }}
                    dataSource={products}
                    renderItem={(item) => {
                        let productStock = item.unlimited_stock == 1 ? 'Unlimited Stock' : (item.stock > 0 ? `Stock ${item.stock ?? 0}` : "Out Of Stock")
                        let color = item.unlimited_stock ? '#289418' : (item.stock > 0 ? "#4b90ff" : "#e7494c")
                        return (
                            <List.Item
                                style={{ cursor: "pointer" }}
                                key={item.id}
                                onClick={() => {
                                    if (item.unlimited_stock != 1 && (item.stock <= 0 || !item.stock)) {
                                        return message.error('The item is out of stock.');
                                    };

                                    addToCart(item, 1);
                                }}>
                                {item.image !== null ? (
                                    <Card bodyStyle={{ padding: '5px' }}>
                                        {/* <div style={{color: item.isCombo ? "Green" : item.stock === 0 || !item.stock ? "red" : "blue",  position: 'relative',  bottom: 15, right: 20}}>
                                                {item.isCombo ? "Combo Product" : !item.stock || item.stock === 0 ? "Out Of Stock" : "In Stock"}
                                         </div> */}
                                        <Image height='auto' width='100%' src={item.image} preview={false} />
                                        <br />
                                        <div style={{ overflow: 'hidden', height: '48px' }}>
                                            <Text style={{ fontWeight: 'bold', fontSize: 16, lineHeight: '1px' }}>{item.name}</Text>
                                        </div>
                                        <Text><i>{currency} {item.price}</i></Text>
                                        <Button
                                            style={{
                                                width: '100%',
                                                background: color,
                                                color: '#fff'
                                            }}
                                            key={item.id}>
                                            {productStock}
                                        </Button>
                                    </Card>
                                ) : (
                                    <Card style={{ borderColor: color }} bodyStyle={{ padding: '10px' }}>
                                        {/* <div style={{ overflow: 'hidden', height: '45px' }}> */}
                                        <Text style={{ fontWeight: 'bold', fontSize: 16, color: color }}>{item.name}</Text>
                                        {/* </div> */}
                                        <div style={{ display: 'flex', justifyContent: "space-between" }}>
                                            <Text><i> {currency} {item.price}</i></Text>
                                            <Text style={{ color: color }}>
                                                {productStock}
                                            </Text>
                                        </div>
                                    </Card>
                                )}
                            </List.Item>
                        )
                    }}
                />
            </div>
        </Card >
    )
}

export default Products