import { ajaxService, loadState, urlService } from "../services";
import { Response, loadResource } from "../utility";

const setDependency = () => { ajaxService.setDependency() }

export async function getProducts(refresh = false) {
    setDependency()
    if (refresh) {
        return await loadResource(urlService.products, 'products');
    } else {
        let products = await loadState('products');

        if (products == null) {
            return await loadResource(urlService.products, 'products');
        } else {
            return Response(products)
        }
    }
}

export async function filterProduct(data) {
    setDependency()
    return await ajaxService.post(urlService.productFilter, data).then(response => {
        return Response(response.data);
    });
}

export async function getFeatured() {
    setDependency()
    return await ajaxService.get(urlService.productFeatured).then(response => {
        return Response(response.data);
    });
}