import { Badge, Button, Card, Space } from "antd"
import { useOnlineStatus } from "../../../hooks";

const Cart = ({ resumeModal,invoiceShortcut, discard, count, enableCartParking, children }) => {

    return (
        <Card>
            <Space style={{ width: '100%', display: 'flex', justifyContent: "space-between" }}>
                <Space>
                    {
                        enableCartParking &&
                        <div style={{ marginRight: 7 }}>
                            <Badge count={count}>
                                <Button type="primary" onClick={() => resumeModal()}>Resume (Ctrl + r)</Button>
                            </Badge>
                        </div>
                    }
                    <Button type="primary" danger onClick={discard}>Discard (Ctrl + d)</Button>
                </Space>
                <Space>
                    <Button style={{ background: '#003366', color: 'white' }} onClick={() => invoiceShortcut()}>Previous Invoice (Ctrl + i)</Button>
                </Space>
            </Space>
            {children}
        </Card>
    )
}

export default Cart