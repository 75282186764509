import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getCashRegisterEntries, saveCashRegister } from "../../data";
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Button, Card, Form, Image, Input, Modal, notification, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import windowSize from "react-window-size";

const { Title } = Typography
function CashRegister() {

    const [showModal, setShowModal] = useState(false)
    const location = useLocation();

    const [form] = Form.useForm();
    let history = useHistory()
    let cashRegisterId = localStorage.getItem('cashRegister_id')
    const [closing, setClosing] = useState('')


    const onFinish = async (values) => {

        let data = {
            opening_amount: values.opening_amount,
            is_open: 1,
            description: values.description,
        }

        const response = await saveCashRegister(data)

        if (response) {
            localStorage.setItem('cashRegister_id', response.id)
            if (location.search) {
                history.push({ pathname: "/" });
            } else {
                history.push({ pathname: "/cash-register/entries/" + response.id });
            }
            notification.open({ message: 'Opening amount has been added successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
        } else {
            notification.open({ message: 'Unable to add opening amount', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }

    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const initialize = async () => {
        const data = await getCashRegisterEntries(cashRegisterId)

        let closingFloat = data.find(i => i.type == 'Closing')

        setClosing(closingFloat)
    }

    useEffect(() => {
        initialize()
        if (!closing && cashRegisterId != null) {
            history.push({ pathname: "/cash-register/entries/" + cashRegisterId });
        }
    }, [])

    const renderHeading = () => {
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Title level={3}>Cash Register</Title>
                </div>
            </>
        )
    }

    const renderModal = () => (
        <Form
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            form={form}
        >
            <Modal
                title="Open Register"
                centered
                open={showModal}
                onCancel={() => setShowModal(false)}
                footer={[
                    <Button key="save" type="primary" onClick={form.submit}>
                        Save
                    </Button>,
                    <Button key="back" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                ]}>
                <Form.Item
                    name='opening_amount'
                    label="Enter Opening Float"
                    labelCol={{ span: 24 }}
                    rules={[
                        {
                            required: true,
                            message: 'Opening float is required',
                        },
                    ]}>
                    <Input type="number" min={0} step={0.01} />
                </Form.Item>
                <Form.Item
                    name='description'
                    label="Description"
                    labelCol={{ span: 24 }}>
                    <TextArea></TextArea>
                </Form.Item>
            </Modal>
        </Form>
    )

    return (
        <>
            <Card>
                {renderHeading()}
                <br />
                <div style={{ textAlign: 'center' }}>
                    <Title level={3}>Register is closed</Title>
                    <Title level={3}>{ }</Title>
                    <Image
                        preview={false}
                        width={200}
                        src='/img/itemScanner.png'
                    />
                    <br /><br /><br />
                    <Button type="primary" onClick={() => setShowModal(true)}>Open Register</Button>
                </div>
            </Card>
            {renderModal()}
        </>
    )

}

export default windowSize(CashRegister)

