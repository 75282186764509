import { useEffect } from "react";
import { Button, Col, Divider, Input, Row, Space, Typography, message } from "antd"
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { useCart } from "react-use-cart";
import { getAllProducts, getProduct, updateProduct } from "../../../services/sql-service";
import ComboStockCalculator from "../../../constant/comboStockCalculator";

const { Text } = Typography

const CartItemsRow = ({ item, openModal, products, db, getProductsFromWebSql, editableUnitPrice }) => {

    const comboInCart = localStorage.getItem('comboInCart') ?? false;

    const { removeItem, updateItemQuantity, updateItem, items } = useCart();

    function setCombPossibleQuanitity() {
        setTimeout(async () => {
            let response = await getAllProducts(db)
            const comboProducts = products.filter(i => i.isCombo == 1)

            const possibleStock = ComboStockCalculator(comboProducts, response)

            for(let i in possibleStock){
                await updateProduct(db, { stock: i.productQuantity, price: i.price }, i.product_id, false)
            }

            getProductsFromWebSql()
        }, 500);
    }

    function handleComboQuantity() {
        let result = 0
        if (comboInCart) {
            const productsWithCombo = items.filter(i => i.isCombo == 1)
            productsWithCombo.map(prodWithCombo => {
                (prodWithCombo.productCombo).map(combo => {
                    if (item.product_id === combo.item_id) {
                        result = Math.ceil(combo.quantity) * prodWithCombo.quantity;
                    }
                })
            })
        }
        return result;
    }

    const handleRemove = async (item) => {

        let quantity = parseInt(item.quantity)

        if (item.isCombo) {

            for (let r in item.productCombo) {
                let stock = quantity * Math.ceil(r.quantity)
                let response = await getProduct(db, r.product_id, false)
                await updateProduct(db, { stock: response.stock + stock, price: response.price }, r.product_id, false);
            }
        }

        let result = handleComboQuantity();

        let response = await getProduct(db, item.product_id, false)
        await updateProduct(db, { stock: response[0].available_stock - result, price: item.price }, item.product_id, false);
        
        if (!item.isCombo) {
            setCombPossibleQuanitity()
        }
        
        removeItem(item.id)
        getProductsFromWebSql()
    }

    const handleQuantity = async (event, item) => {

        let quantity = parseInt(event.target.value);

        if(quantity > item.available_stock){
            message.error('The desired quantity is not available');
            return;
        }

        if (isNaN(quantity)) {
            quantity = 0;

            if (item.isCombo) {

                for (let r in item.productCombo) {
                    let stock = item.quantity * Math.ceil(r.quantity);
                    let response = await getProduct(db, r.product_id, false)
                    await updateProduct(db, { stock: response.stock + stock, price: response.price }, r.product_id, false);
                }
            }

            let result = handleComboQuantity();

            let response = await getProduct(db, item.product_id, false)

            if (response && response.length > 0) {

                let product = response[0];
                let stock = product.available_stock - quantity - result;

                await updateProduct(db, { stock: stock - quantity - result, price: item.price }, item.product_id, false);
                setCombPossibleQuanitity()
            }

        } else {
            if (item.isCombo) {

                for (let r in item.productCombo) {
                    let stock = quantity * Math.ceil(r.quantity)
                    let qty = items.find(i => i.product_id === r.item_id)?.quantity ?? 0;
                    let response = await getProduct(db, r.product_id, false)
                    if (response) {
                        await updateProduct(db, { stock: response.available_stock - stock - qty, price: response.price }, r.product_id, false);
                    }
                }

                setCombPossibleQuanitity()

            } else {

                let result = handleComboQuantity();

                let response = await getProduct(db, item.product_id, false)
                if (response && response.length > 0) {

                    let product = response[0];
                    let stock = product.available_stock - quantity - result;

                    await updateProduct(db, { stock: stock, price: item.price }, item.product_id, false);
                    setCombPossibleQuanitity()
                }
            }
        }

        updateItemQuantity(item.id, quantity);
        getProductsFromWebSql()
    }

    let discount = 0

    if (item.discountRow) {
        discount = item.percentage ? (item.discountRow / 100) * item.itemTotal : item.perUnit ? (item.discountRow * item.quantity) : item.discountRow
    }

    let itemSubtotal = item.itemTotal - discount;

    useEffect(() => {
        updateItem(item.id, { rowSubtotal: itemSubtotal, rowDiscountAmount: discount })
    }, [itemSubtotal, products])

    const renderCombo = (row) => {
        if (row.isCombo === 1) {
            return row.productCombo.map(i => {
                const p = products.find(obj => obj.product_id === i.item_id);
                return (
                    <Row style={{ marginLeft: 20, marginTop: 14 }}>
                        <Col span={5}>
                            <Text style={{ color: '#00CC66' }}>{p?.name}</Text>
                        </Col>
                        <Col span={4}>
                            <Text style={{ color: '#00CC66' }}>{parseInt(i.quantity)}</Text>
                        </Col>
                    </Row>
                );
            });
        }
    }

    return (
        <>
            <Row>
                <Col span={4}>
                    <Text>{item.name}</Text>
                </Col>

                <Col span={1}></Col>
                <Col span={3} style={{ textAlign: 'center' }}>
                    <Input
                        type="number"
                        onChange={(e) => handleQuantity(e, item)}
                        value={item.quantity}
                    />
                </Col>

                <Col span={1}></Col>
                <Col span={4} style={{ textAlign: 'center' }}>
                    <Input type="number" step={0.01}
                        readOnly={!editableUnitPrice}
                        onChange={(e) => updateItem(item.id, { price: e.target.value })} defaultValue={item.price}
                    />
                </Col>

                <Col span={4} style={{ textAlign: 'center' }}>
                    {item.discountRow}{item.percentage ? '%' : ''}
                </Col>

                <Col span={4} style={{ textAlign: 'center' }}>
                    {(itemSubtotal).toFixed(2)}
                </Col>

                <Col span={3}>
                    <Space>
                        <Button size="small" type="primary" icon={<EditOutlined />}
                            onClick={() => openModal(item.id)} />
                        <Button size="small" type="primary" danger icon={<CloseOutlined />}
                            onClick={() => handleRemove(item)} />
                    </Space>
                </Col>

                <Col span={24}>
                    {renderCombo(item)}
                </Col>
            </Row>
            <Divider />
        </>
    )
}
export default CartItemsRow