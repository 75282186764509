import { ajaxService, loadState, urlService } from "../services";
import { loadResource, Response } from "../utility";

const setDependency = () => { ajaxService.setDependency() }

export async function saveCustomer(data) {
    setDependency()
    return ajaxService.post(urlService.customers, data).then(response => {
        return Response(response.data);
    })
}