import { Button, Col, Form, Input, notification, Row, Space } from "antd";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { updateProfile } from "../../data";
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

export default function ChangePasswordForm() {
    const [form] = Form.useForm()
    let history = useHistory()
    let { id } = useParams()

    const onFinish = async (values) => {
        const updateData = await updateProfile(id, values)
        if (updateData) {
            history.push({ pathname: '/profile' });
            notification.open({ message: 'Password has been changed', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
        } else {
            notification.open({ message: 'Unable to change password', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    }

    return (
        <>
            <Form
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}>
                <Row gutter={16}>
                    <Col span={6}></Col>
                    <Col span={12}>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="New Password" name='password' hasFeedback rules={[
                                    {
                                        required: true,
                                        message: 'Password is required'
                                    },
                                    {
                                        min: 8,
                                        message: 'Password must be at least 8 characters'
                                    }
                                ]}>
                                    <Input.Password placeholder="New Password" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Confirm Password"
                                    name={'confirm'}
                                    dependencies={['password']}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Confirm your password!',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error("Confirm password does not match to password"));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password placeholder="Change Password" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Form.Item>
                                <Space>
                                    <Button htmlType="submit" type="primary">Submit</Button>
                                    <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                                </Space>
                            </Form.Item>
                        </Row>
                    </Col>
                </Row>

            </Form>
        </>
    )
}