import React from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Form, Input, Button, Checkbox, Card, Row, Col, notification } from 'antd';
import { FacebookOutlined, TwitterOutlined, GoogleOutlined, InstagramOutlined, CloseCircleOutlined } from '@ant-design/icons';
import ajaxService from '../../services/ajax-service'
import { getUser } from '../../data';

const data = [
    {
        content: (
            <Button type="primary" shape="round" icon={<FacebookOutlined />} size={'large'} style={{ width: '250px' }} >
                <span> Sign in with facebook</span>
            </Button>
        )
    },
    {
        content: (

            <Button type="primary" shape="round" icon={<TwitterOutlined />} size={'large'} style={{ width: '250px' }} >
                <span>Sign in with twitter</span>
            </Button>
        )
    },
    {
        content: (
            <Button type="primary" shape="round" icon={<GoogleOutlined />} size={'large'} style={{ width: '250px' }} >
                <span>Sign in with google</span>
            </Button>
        )
    },
    {
        content: (
            <Button type="primary" shape="round" icon={<InstagramOutlined />} size={'large'} style={{ width: '250px' }} >
                <span>Sign in with instagram</span>
            </Button>
        )
    },
];

const Login = () => {

    let history = useHistory();
    let location = useLocation();
    let { from } = location.state || { from: { pathname: "/" } };

    const onFinish = async (values) => {
        const response = await ajaxService.getAccessToken(values.username, values.password);

        let { access_token, message, title, success } = response.data        

        if (success) {
            localStorage.setItem('token', access_token);
            const user = await getUser();
            if (user) {
                if (user.is_super_admin) {
                    notification.open({ message: 'You are not authorized to login into store', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
                } else {
                    localStorage.setItem('store_id', user.store_id);
                    if (user.user_branches.length === 1) {
                        const branch_id = user.user_branches[0].branch_id
                        localStorage.setItem('posBranchId', branch_id)
                        localStorage.setItem('currency',user.user_store.currency)
                        history.replace(from);
                    } else {
                        history.push({ pathname: '/branches' })
                    }
                }
            }
        } else {
            notification.open({ message: title, description: message, icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '70px', marginBottom: '70px' }} >
            <Col span={3}></Col>
            <Col span={18}>
                <Row>
                    <Col span={8}>

                    </Col>
                    <Col span={8}>
                        <Card title="Sign in to your account" bordered={false} style={{ width: '100%' }}>
                            <Form
                                //{...layout}
                                layout={'vertical'}
                                name="basic"
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                            >
                                <Form.Item
                                    label="Username"
                                    name="username"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Username is required',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="Password"
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Password is required',
                                        },
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>

                                <Form.Item name="remember" valuePropName="checked">
                                    <Checkbox>Keep me signed in</Checkbox>
                                </Form.Item>

                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                    <Col span={8}>

                    </Col>
                </Row>

            </Col>
            <Col span={3}></Col>
        </Row>
    );
};

export default Login