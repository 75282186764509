import { Button, Form, Modal } from "antd"

const FormModal = ({ type, visible, toggle, form, onFinish, onFinishFailed, children }) => (
    <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
    >
        <Modal
            width="40%"
            title={type == "addCustomer" ? "Quick Add Customer" : "Park Sale"}
            centered
            open={visible}
            onCancel={() => { toggle(false) }}
            footer={[
                <Button key="save" type="primary" onClick={form.submit}>
                    {type == "addCustomer" ? "Save" : "Apply"}
                </Button>,
                <Button key="back" onClick={() => { toggle(false) }}>
                    Close
                </Button>
            ]}>
            {children}
        </Modal>
    </Form>
)

export default FormModal