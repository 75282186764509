import React, { useState, useEffect } from 'react';
import { Table, Space, Tag, Tooltip, Form, Row, Col, notification, DatePicker, Input, Menu, Select, Button, Dropdown, Card, Typography, Radio } from 'antd';
import { useHistory } from 'react-router-dom';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { filterOrder, getOrders, updateStatus } from '../../data';
import windowSize from 'react-window-size';
import OrderStatus from '../../constant/orderStatus';

const { RangePicker } = DatePicker;
const { Option } = Select
const { Text } = Typography

function Orders({ windowWidth }) {

  const [dataSource, setDataSource] = useState([])
  const [orderNumber, setOrderNumber] = useState(null)
  const [customer, setCustomer] = useState(null)
  const [customerName, setCustomerName] = useState(null)
  const [StatusOrder, setStatus] = useState(null)
  const [dateRange, setDateRang] = useState('')
  const [forceRefresh, setForceRefresh] = useState(true)
  const isMobile = windowWidth <= 768

  const history = useHistory();

  const [type, setType] = useState(1)

  const [posOrders, setPosOrders] = useState([])
  const [ecommerceOrders, setEcommerceOrders] = useState([])
  const [orders, setOrders] = useState([])

  const status = Object.values(OrderStatus)

  const columns = [
    {
      title: 'Order Number',
      dataIndex: 'order_number',
      key: 'order_number',

    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    }, {
      title: 'Customer',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <>
          <span>{record.name}</span><br />
          <span>{record.email}</span><br />
          <span>{record.phone}</span>
        </>
      )
    },
    {
      title: 'Shipping',
      dataIndex: 'shipping',
      key: 'shipping',
      ellipsis: {
        showTitle: false,
      },
      render: address => (
        <Tooltip placement="topLeft" title={address}>
          {address}
        </Tooltip>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: text => (
        <Tag color={'geekblue'}>
          {text ? text.toUpperCase() : ''}
        </Tag>
      ),
    },

    {
      title: 'Shipping Address',
      dataIndex: 'shipping_address',
      key: 'shipping_address',
    },

    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      // align: 'left',

      render: (text, record) => (
        <Space >
          {
            record.status != OrderStatus.COMPLETED &&
            <Dropdown overlay={
              <Menu >
                {status.slice(status.indexOf(record.status) + 1).map((status, key) => (
                  <Menu.Item onClick={() => changeStatus(record.id, status)} key={key}>
                    {status}
                  </Menu.Item>
                ))}
              </Menu>}
              placement="bottomCenter">
              <Button>Status</Button>
            </Dropdown>
          }
          <Button onClick={() => history.push({ pathname: "/orders/form/" + record.id })}> View</Button>
          {record.status == OrderStatus.COMPLETED && <Button type='primary' onClick={() => history.push({ pathname: "/orders/print/" + record.id })}>Print</Button>}

        </Space >
      ),
    },
  ];

  const handleSearch = async () => {
    var formData = new FormData();

    if (orderNumber !== null) {
      formData.append('orderNumber', orderNumber);
    }
    if (StatusOrder !== null) {
      formData.append('StatusOrder', StatusOrder);
    }
    if (customer !== null) {
      formData.append('customer', customer);
    }
    if (dateRange !== '') {
      formData.append('dateRange', dateRange);
    }
    if (customerName !== null) {
      formData.append('customerName', customerName);
    }
    const response = await filterOrder(formData);

    if (response) {
      setPosOrders(response.filter(i => i.platform == "POS"));
      setEcommerceOrders(response.filter(i => i.platform != "POS"));
    } else {
      notification.open({ message: 'No Order Found...', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
    }

  }

  const clear = () => {
    setOrderNumber(null)
    setCustomer(null)
    setStatus(null)
    setDateRang('')
    setCustomerName(null)
  }

  const changeStatus = async (id, status) => {
    let data = {
      id: id,
      status,
    }
    const update_status = await updateStatus(id, data);
    if (update_status) {
      setForceRefresh(true)
      notification.open({ message: 'Order status has been updated', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
    } else {
      notification.open({ message: 'Unable to update order status', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })

    }
  }

  const initialize = async () => {
    const data = await getOrders(forceRefresh);
    if (data) {
      setOrders(data.filter(i => type === 1 ? i.platform == "POS" : i.platform != "POS"))
      setPosOrders(data.filter(i => i.platform == "POS"));
      setEcommerceOrders(data.filter(i => i.platform != "POS"));
      setForceRefresh(false)
    }
  }

  useEffect(() => {
    if (forceRefresh) {
      initialize()
      setForceRefresh(false)
      clear()
    }
  }, [forceRefresh])

  const renderFilter = (
    <Form layout={'vertical'}>
      <Row gutter={16} >
        <Col span={isMobile ? 24 : 6} >
          <Form.Item label="Order Number" >
            <Select
              onChange={(v) => setOrderNumber(v)}
              // value={orderNumber === '' ? 'Select Order' : orderNumber}
              value={orderNumber}
              placeholder="Select Order"
              showSearch
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {orders.map(order => {
                return <Option value={order.order_number} key={"order-" + order.id} >{order.order_number}</Option>
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={isMobile ? 24 : 6}>
          <Form.Item label="Order Status">
            <Select
              allowClear
              placeholder="Select Order Status"
              value={StatusOrder}
              onChange={(v) => setStatus(v)}
            >
              {status.map(status => {
                return <Option value={status} key={"status-" + status} >{status}</Option>
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={isMobile ? 24 : 6} >
          <Form.Item label="Customer" >
            <Select
              showSearch
              allowClear
              value={customer}
              placeholder="Select Customer"
              onChange={(v) => setCustomer(v)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {orders.map(customer => {
                return <Option value={customer.name} key={"customer-" + customer.id} >{customer.name}</Option>
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={isMobile ? 24 : 6}>
          <Form.Item label="Date Range" >
            <RangePicker style={{ width: '100%' }} value={dateRange} onChange={(e) => setDateRang(e)} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={isMobile ? 24 : 6}>
          <Form.Item label="Customer Filter" >
            <Input value={customerName}
              placeholder="Customer Name"
              onChange={(e) => setCustomerName(e.target.value)} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={isMobile ? '' : <Text>&nbsp;</Text>}>
            <Space>
              <Button onClick={handleSearch}>Search</Button>
              <Button onClick={clear}>Clear</Button>
            </Space>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )

  const renderOrders = () => {
    return (
      <>
        <Radio.Group optionType="button" buttonStyle="solid" defaultValue={type} onChange={(e) => { setType(e.target.value); setForceRefresh(true) }}>
          <Radio value={2}>Ecommerce</Radio>
          <Radio value={1}>POS</Radio>
        </Radio.Group>

        <br /><br />

        <Row>
          <Col span={24}>
            <Table
              dataSource={type === 1 ? posOrders : ecommerceOrders}
              columns={columns}
              rowKey="id"
            />
          </Col>
        </Row>

      </>
    )
  }

  // const renderFilterMobile = () => {
  //   return (
  //     <>
  //       <Form>
  //         <Row gutter={16} >
  //           <Col span={24} >
  //             <Form.Item label="Order Number" >
  //               <Select
  //                 onChange={(v) => setOrderNumber(v)}
  //                 value={orderNumber === '' ? 'Select Order' : orderNumber}
  //                 showSearch
  //                 allowClear
  //                 filterOption={(input, option) =>
  //                   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  //                 }
  //               >
  //                 {dataSource.map(order => {
  //                   return <Option value={order.order_number} key={"order-" + order.id} >{order.order_number}</Option>
  //                 })}
  //               </Select>
  //             </Form.Item>
  //           </Col>
  //           <Col span={24}>
  //             <Form.Item label="Order Status">
  //               <Select
  //                 allowClear
  //                 // placeholder="Select Order Status"
  //                 value={StatusOrder === '' ? 'Select Order Status' : StatusOrder}
  //                 onChange={(v) => setStatus(v)}
  //               >
  //                 {status.map(status => {
  //                   return <Option value={status} key={"status-" + status} >{status}</Option>
  //                 })}
  //               </Select>
  //             </Form.Item>
  //           </Col>
  //           <Col span={24} >
  //             <Form.Item label="Customer" >
  //               <Select
  //                 showSearch
  //                 allowClear
  //                 value={customer === '' ? 'Select Customer' : customer}
  //                 onChange={(v) => setCustomer(v)}
  //                 filterOption={(input, option) =>
  //                   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  //                 }
  //               >
  //                 {dataSource.map(customer => {
  //                   return <Option value={customer.name} key={"customer-" + customer.id} >{customer.name}</Option>
  //                 })}
  //               </Select>
  //             </Form.Item>
  //           </Col>
  //           <Col span={24}>
  //             <Form.Item label="Date Range" >
  //               <RangePicker style={{ width: '100%' }} value={dateRange} onChange={(e) => setDateRang(e)} />
  //             </Form.Item>
  //           </Col>
  //           <Col span={24}>
  //             <Form.Item label="Customer Filter" >
  //               <Input value={customerName === '' ? 'Customer Name' : customerName}
  //                 onChange={(e) => setCustomerName(e.target.value)} />
  //             </Form.Item>
  //           </Col>
  //           <Col span={24}>
  //             <Form.Item>
  //               <Space>
  //                 <Button type='primary' onClick={handleSearch}>Search</Button>
  //                 <Button onClick={clear}>Clear</Button>
  //               </Space>
  //             </Form.Item>
  //           </Col>
  //         </Row>
  //       </Form>
  //     </>
  //   )
  // }

  const renderOrdersMobile = () => {
    return "working........"
    // return (
    //   <>
    //     {renderFilterMobile()}
    //     {
    //       dataSource.map(order => {
    //         return (
    //           <>
    //             < Card style={{ width: '100%' }}
    //               actions={[
    //                 <Space >
    //                   <Dropdown overlay={
    //                     <Menu >
    //                       {status.map((status, key) => (
    //                         <Menu.Item onClick={() => changeStatus(order.id, status)} key={key}> {status} </Menu.Item>
    //                       ))}
    //                     </Menu>}
    //                     placement="bottom">
    //                     <Button>Status</Button>
    //                   </Dropdown>
    //                   <Button type='primary' onClick={() => history.push({ pathname: "/orders/form/" + order.id })}>View</Button>
    //                 </Space>
    //               ]}>
    //               <Text style={{ fontWeight: 'bolder' }}>Order Number : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{order.order_number}</span></Text>
    //               <br />
    //               <Text style={{ fontWeight: 'bolder' }}>Date : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{order.date}</span></Text>
    //               <br />
    //               <Text style={{ fontWeight: 'bolder' }}>Customer :
    //                 <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{order.name}</span><br />
    //                 <span style={{ fontWeight: 'lighter', marginLeft: 78 }}>{order.email}</span><br />
    //                 <span style={{ fontWeight: 'lighter', marginLeft: 78 }}>{order.phone}</span>
    //               </Text>
    //               <br />
    //               <Text style={{ fontWeight: 'bolder' }}>Shipping : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{order.shipping}</span></Text>
    //               <br />
    //               <Text style={{ fontWeight: 'bolder' }}>Status :
    //                 <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>
    //                   <Tag color='geekblue'>
    //                     {order.status}
    //                   </Tag>
    //                 </span>
    //               </Text>
    //               <br />
    //               <Text style={{ fontWeight: 'bolder' }}>Shipping Address : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{order.shipping_address}</span></Text>
    //               <br />
    //               <Text style={{ fontWeight: 'bolder' }}>Total : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{order.total}</span></Text>
    //               <br />
    //             </Card>
    //             <br />
    //           </>
    //         )
    //       })
    //     }
    //   </>
    // )
  }

  const renderContent = () => {
    if (isMobile) {
      return renderOrdersMobile()
    }
    else {
      return renderOrders()
    }
  }

  return (
    <>
      {renderFilter}
      {renderContent()}
    </>
  )

}

export default windowSize(Orders)