import { useEffect, useState } from "react"
import { useCart } from "react-use-cart";
import { PlaceOrder } from "../../../data";
import { Button, Col, Divider, Form, Input, Modal, Row, message } from "antd"
import OrderStatus from "../../../constant/orderStatus";
import Limits from "../../../constant/storeLimits";
import moment from "moment";
import { useOnlineStatus } from "../../../hooks";
import { getOrder, getOrderItems, getProductByProductID, insertOrderItems, insertOrders, updateProductAvailableStock, updateStockFromAvailableStock } from "../../../services/sql-service";
import { useHotkeys } from 'react-hotkeys-hook';

const SellModal = ({
    visible,
    toggle,
    customer,
    customerId,
    shipping,
    notes,
    salePrintModal,
    saleOrder,
    saleOrderItems,
    subtotal,
    setCustomerId,
    cartSummaryTotal,
    branch_id,
    discount,
    setDiscount,
    setAmountDiscount,
    setPercentageDiscount,
    setShippingCharges,
    setNotes,
    setLoadScreen,
    store,
    db,
    srb,
}) => {

    const isOnline = useOnlineStatus();

    const storeID = localStorage.getItem('store_id');
    const branchID = localStorage.getItem('posBranchId');

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    const startDate = `${year}-${month}-01`;
    const todayDate = `${year}-${month}-${day}`;

    let orders = store?.orders ?? null;
    let storeRestriction = null;

    useHotkeys('enter', () => handleSaleComplete())

    if (orders) {
        orders = orders.filter(i => moment(i.date).format('Y-M-DD') >= startDate && moment(i.date).format('Y-M-DD') <= todayDate);
        storeRestriction = store && store.store_restrictions ? store.store_restrictions : null;
    }

    const [sellingForm] = Form.useForm()
    const [pay, setPay] = useState(0)

    const { items, emptyCart } = useCart();

    let total = cartSummaryTotal

    let totalBalance = 0

    totalBalance = pay - total

    if (customer) {
        totalBalance = (total - pay) + parseInt(customer.balance != 0 ? customer.balance : 0)
    }

    const checkStock = async (items) => {
        for (const i of items) {
            const product = await new Promise(async (resolve, reject) => {
                let response = await getProductByProductID(db, i.product_id, false)
                resolve(response);
            });
            if (i.quantity > product.stock) {
                return message.error("You've exceeded the available quantity. Please adjust your quantity.");
            }
        }
    }

    const saleComplete = async (values) => {

        let amountPaid = sellingForm.getFieldValue().amount_paid;

        if (amountPaid < total && customerId === 0) {
            return message.error('Insufficient Amount Entered')
        }

        // For checking right quantity
        const moreStock = await checkStock(items)

        if (!moreStock) {
            let data = {
                amount_paid: values.amount_paid,
                amount_changed: totalBalance,
                subtotal: subtotal,
                shipping: shipping,
                discount: discount,
                total: total,
                notes: notes,
                order_items: items,
                customer_id: customerId,
                name: customer.name,
                phone: customer.phone,
                cash_register_id: parseInt(localStorage.getItem('cashRegister_id')),
                branch_id: branch_id,
                is_grocery_pos: true,
                status: OrderStatus.COMPLETED,
                platform: 'POS'
            }

            if (isOnline) {
                const orderData = await PlaceOrder(data)
                if (orderData) {
                    afterPlaceOrder(orderData)
                }
            } else {

                const date = moment().format('YYYY-MM-DD HH:mm');
                
                let row = {
                    temporary_order_id: date,
                    order_number: 'ORDER' + moment().format('YYYYMMDDHHmm'),
                    store_id: storeID,
                    branch_id: branchID,
                    name: data.name,
                    phone: data.phone,
                    customer_id: data.customer_id,
                    date: date,
                    status: data.status,
                    amount_changed: data.amount_changed,
                    amount_paid: data.amount_paid,
                    discount: data.discount,
                    shipping: data.shipping,
                    subtotal: data.subtotal,
                    cash_register_id: data.cash_register_id,
                    is_grocery_pos: data.is_grocery_pos,
                    notes: data.notes,
                    platform: data.platform,
                    total: data.total,
                }

                const orderData = [null, null];

                let id = await insertOrders(db, row)

                if (id > 0) {

                    for (let key in data.order_items) {
                        let orderItem = data.order_items[key];
                        orderItem.order_id = id;
                        insertOrderItems(db, orderItem)
                        let response = await getProductByProductID(db, orderItem.product_id, false)
                        if (response.length > 0) {
                            const prod = response[0]
                            const availableStock = prod.available_stock - orderItem.quantity;
                            await updateProductAvailableStock(db, availableStock, prod.product_id)
                            updateStockFromAvailableStock(db, [prod])
                        }
                    }

                    let order = await getOrder(db, id)
                    srbCalculation(order)
                    orderData[0] = order;

                    let items = await getOrderItems(db, id)

                    const rows = [];

                    items.forEach(orderItem => {
                        let row = {
                            product_name: orderItem.name,
                            selling_price: orderItem.price,
                            quantity: orderItem.quantity,
                            discount: orderItem.rowDiscountAmount,
                            total: orderItem.rowSubtotal,
                        };

                        rows.push(row);
                    });

                    orderData[1] = rows;
                    afterPlaceOrder(orderData)
                };
            }
        }
    }

    const srbCalculation = (order) => {
        const { active, settings } = srb;

        if (active) {
            let total = parseInt(order.total);
            let netTotal = total;
            let taxRate = 0;
            let taxAmount = 0;

            if (settings) {
                if (settings.tax_type == "inclusive") {
                    netTotal = total / (1 + (settings.tax_rate / 100));
                    taxAmount = total - netTotal;
                }

                if (settings.tax_type == "exclusive") {
                    taxAmount = total * (settings.tax_rate / 100);
                    total += taxAmount;
                }

                taxRate = settings.tax_rate ?? 0;

                order.net_total = parseFloat(netTotal.toFixed(2));
                order.tax_rate = taxRate;
                order.tax_amount = parseFloat(taxAmount.toFixed(2));
                order.total = parseFloat(total.toFixed(2))
            }
        }
    }

    const afterPlaceOrder = (orderData) => {
        saleOrder(orderData[0])
        saleOrderItems(orderData[1])
        salePrintModal(true)
        emptyCart()
        toggle(false)
        sellingForm.resetFields()
        totalBalance = 0;
        setPay(0)
        setCustomerId(0)
        setDiscount(0)
        setAmountDiscount(0)
        setPercentageDiscount(0)
        setShippingCharges(0)
        setNotes('')
        if (isOnline) {
            setLoadScreen(true)
        }
    }

    const handleSaleComplete = () => {

        if (orders) {

            let orderLimit = storeRestriction.find(i => i.type === Limits.POS_ORDER_LIMITS)?.value || 0;

            if (!storeRestriction || orderLimit == 0 || orderLimit >= orders.length) {
                sellingForm.submit()
            } else {
                Modal.error({
                    title: 'Exceeding Limits',
                    content: Limits.MESSAGE,
                })
            }
        } else {
            sellingForm.submit()
        }
    }

    /*
    const handleSaleComplete = () => {
        if (orders) {

            if (!storeRestriction) {
                sellingForm.submit()
                return;
            }

            let posOrdersLimit = storeRestriction.find(i => i.type === Limits.POS_ORDER_LIMITS);

            console.log(orders,posOrdersLimit)


            if (posOrdersLimit) {

                if (posOrdersLimit.value == 0) {
                    sellingForm.submit()
                    return;
                }

                if (posOrdersLimit.value > 0 && posOrdersLimit.value < orders.length) {
                    sellingForm.submit()
                    return;
                }
            }

            Modal.error({
                title: 'Exceeding  Limits',
                content: Limits.MESSAGE,
            })

        } else {
            sellingForm.submit()
        }
    }
    */

    useEffect(() => {
        setPay(cartSummaryTotal)
        sellingForm.setFieldValue('amount_paid', cartSummaryTotal)
    }, [cartSummaryTotal])

    return (
        <>
            <Modal
                width="40%"
                title="Current Sale"
                centered
                open={visible}
                destroyOnClose
                onCancel={() => toggle(false)}
                footer={[
                    <Button key="save" type="primary"
                        onClick={handleSaleComplete}
                    >
                        Complete
                    </Button>,
                    <Button key="back"
                        onClick={() => toggle(false)}
                    >
                        Close
                    </Button>
                ]}>
                <Form
                    onFinish={saleComplete}
                    form={sellingForm}
                    initialValues={{
                        // amount_paid: cartSummaryTotal
                    }}
                >
                    <Row>
                        <Col span={14}>Customer</Col>
                        <Col span={10} style={{ textAlign: 'right' }}>{customer.name}</Col>
                        <Divider style={{ marginTop: 10 }} />

                        <Col span={14}>Phone</Col>
                        <Col span={10} style={{ textAlign: 'right' }}>{customer.phone}</Col>
                        <Divider style={{ marginTop: 10 }} />

                        <Col span={14}>Balance</Col>
                        <Col span={10} style={{ textAlign: 'right' }}>{customer.balance}</Col>
                        <Divider style={{ marginTop: 10 }} />

                        <Col span={14}>Subtotal</Col>
                        <Col span={10} style={{ textAlign: 'right' }}>{(subtotal).toFixed(2)}</Col>
                        <Divider style={{ marginTop: 10 }} />

                        <Col span={14}>Shipping</Col>
                        <Col span={10} style={{ textAlign: 'right' }}>{(shipping).toFixed(2)}</Col>
                        <Divider style={{ marginTop: 10 }} />

                        <Col span={14}>Discount</Col>
                        <Col span={10} style={{ textAlign: 'right' }}>{(parseInt(discount)).toFixed(2)}</Col>
                        <Divider style={{ marginTop: 10 }} />

                        <Col span={14}>Tax</Col>
                        <Col span={10} style={{ textAlign: 'right' }}>0</Col>
                        <Divider style={{ marginTop: 10 }} />

                        <Col span={14}>Total</Col>
                        <Col span={10} style={{ textAlign: 'right' }}>{(total).toFixed(2)}</Col>
                        <Divider style={{ marginTop: 10 }} />

                        <Col span={14}>Pay</Col>
                        <Col span={10}>
                            <Form.Item name='amount_paid'
                                rules={[
                                    {
                                        required: customerId === 0 ? true : false, message: 'Payment is required'
                                    }
                                ]}
                            >
                                <Input onChange={(e) => setPay(e.target.value)} type="number" step={0.01} />
                            </Form.Item>
                        </Col>
                        <Divider style={{ marginTop: 10 }} />

                        <Col span={14}>Balance / Change</Col>
                        <Col span={10} style={{ textAlign: 'right' }}>
                            {(totalBalance).toFixed(2)}
                        </Col>

                    </Row>
                </Form>
            </Modal>
        </>

    )
}

export default SellModal