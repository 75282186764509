import { Button, Form, Space, Col, Input, Modal, Row, TimePicker } from "antd"
import moment from "moment"
import { useEffect } from "react"

const TakeawayModal = ({ visible, toggle, setFormData, orderInfo, buttonType }) => {

    const format = "HH:mm"

    const time = moment(orderInfo.time, format)

    const [orderForm] = Form.useForm()

    useEffect(() => {
        if (buttonType === 0 || buttonType === 1 || buttonType === 3)
            orderForm.resetFields()
    }, [orderInfo])

    const onFinish = (values) => {
        const json = JSON.stringify(values)
        setFormData({ json, orderType: 2 })
        toggle(false)
    }


    return (
        <Modal
            width="60%"
            title="Take Away"
            centered
            open={visible}
            onCancel={() => toggle(false)}
            footer={[
                <Space key="space">
                    <Button type="primary" key="sbmit" onClick={orderForm.submit}> Submit </Button>
                    <Button key="back" onClick={() => toggle(false)}>
                        Close
                    </Button>
                </Space>
            ]} >
            <Form form={orderForm} onFinish={onFinish}>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item name="time" label="Time" labelCol={{ span: 24 }}
                            initialValue={orderInfo.time ? time : null}
                            rules={[
                                {
                                    required: true,
                                    message: 'Field is required'
                                }
                            ]}>
                            <TimePicker style={{ width: '100%' }} key={time} />
                        </Form.Item>
                    </Col>

                </Row>
            </Form>
        </Modal >
    )
}

export default TakeawayModal