import { useState, useEffect } from "react";
import { Button } from "antd";
import useWebSocket from "../../../utility/use-websocket";

const Categories = ({ search }) => {

    const { socketHandler } = useWebSocket()
    const [categories, setCategories] = useState([])
    const branch_id = localStorage.getItem('posBranchId')

    useEffect(() => {
        // Category
        socketHandler('branch-categories', { branch_id: branch_id }, (response) => {
            setCategories(response)
        });

    }, [])

    return (
        <div className="scrollmenu">
            <Button className="categoryButton" onClick={() => search(0,2)}>Featured</Button>
            {
                categories.map((category, index) => (
                    <Button key={index} className="categoryButton" onClick={() => search(category.id,2)}>{category.name}</Button>
                ))
            }
        </div>
    )
}

export default Categories