import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useCart } from "react-use-cart";
import { CheckCircleOutlined, CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Col, Form, notification, Row, Spin } from "antd";
import windowSize from "react-window-size";
import useWebSocket from "../../utility/use-websocket";
import { getAllParkSale, saveCustomer, saveParkSale, updateParkSale, getStore, getStoreSettings, getFeatured } from "../../data";
import { FormModalFields, ResumedDiscardFields } from "./modal_fields";
import Products from "./cart_products/product";
import Categories from "./cart_products/categories";
import { Cart, Customers, EmptyCartView, CartFooter, CartItems, CartItemsRow, CartItemsSummery } from "./cart";
import { useHotkeys } from 'react-hotkeys-hook';


import {
    EditCartItemModal,
    FormModal,
    PreviousInvoiceModal,
    ResumedDiscardModal,
    SalePrintModal,
    SellModal,
    DineInModal,
    DeliveryModal,
    TakeawayModal,
} from "./modals";
import {
    bulkInsertProducts,
    filterProducts,
    filterProductsByCategoryID,
    filterProductsByKeyword,
    getAllProductCombo,
    getAllProducts,
    getProductByProductID,
    getProductsByProductID,
    initializeDb,
    insertProducts,
    truncateProductCombo,
    truncateProducts,
    updateProduct,
    updateStockFromAvailableStock
} from "../../services/sql-service";
import { ajaxService, sync } from "../../services";
import ControlSettings from "../../constant/controlSettings";
import { useDebounce, useOnlineStatus } from "../../hooks";

function SalesScreen() {

    let db;
    const isOnline = useOnlineStatus();

    let history = useHistory()
    const componentRef = useRef();
    const searchRef = useRef(null);
    const [form] = Form.useForm();
    const [timer, setTimer] = useState(null);

    useHotkeys('shift+d', () => discardShortcut())
    useHotkeys('shift+r', () => resumeShortcut())
    useHotkeys('shift+i', () => invoiceShortcut())
    useHotkeys('shift+p', () => printShortcut())
    useHotkeys('shift+h', () => holdShortcut())
    useHotkeys('shift+s', () => sellShortcut())
    useHotkeys('shift+ctrl', () => searchRef.current.focus())
    // useHotkeys('tab+q', () => searchRef.current.focus())  
    /* reusable shortcuts */
    // const upRef = useHotkeys('up', () => searchRef.current.focus())    
    // const downRef = useHotkeys('down', () => searchRef.current.focus())    
    // const enterRef = useHotkeys('enter', () => searchRef.current.focus())    

    // Local Storage
    const cashRegisterId = localStorage.getItem('cashRegister_id')
    const store_id = localStorage.getItem('store_id')
    const branch_id = localStorage.getItem('posBranchId')

    // Use Cart
    const { items, emptyCart, setItems, cartTotal } = useCart();
    const { socketHandler } = useWebSocket()

    // Checkbox
    const [parentChecked, setParentChecked] = useState(false);
    const [childValue, setChildValue] = useState(1);
    // const [db, setDb] = useState(null);

    // Modals
    const [showModal, setShowModal] = useState(false)
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [showEditItemModal, setShowEditItemModal] = useState(false)
    const [showSellModal, setShowSellModal] = useState(false)
    const [showInvoiceModal, setShowInvoiceModal] = useState(false)
    const [showSalePrintModal, setShowSalePrintModal] = useState(false)
    const [dineInModal, setDineInModal] = useState(false)
    const [deliveryModal, setDeliveryModal] = useState(false)
    const [takeawayModal, setTakeawayModal] = useState(false)
    const [branch, setBranch] = useState(null)

    // Customers
    const [customers, setCustomers] = useState([]);
    const [featured, setFeatured] = useState([]);
    const [customerId, setCustomerId] = useState(0);
    const [customer, setCustomer] = useState({})

    // Products
    const [products, setProducts] = useState([]);
    const [displayProducts, setDisplayProducts] = useState([]);

    // CartHooks
    const [itemsPercentageDiscount, setItemsPercentageDiscount] = useState(0);
    const [itemsAmountDiscount, setItemsAmountDiscount] = useState(0);
    const [shippingCharges, setShippingCharges] = useState(0);
    const [actionType, setActionType] = useState('');
    const [resumedData, setResumedData] = useState([]);
    const [cartItem, setCartItem] = useState('');
    const [notes, setNotes] = useState('')
    const [cartSubtotal, setCartSubtotal] = useState(0)
    const [saleOrder, setSaleOrder] = useState([])
    const [saleOrderItems, setSaleOrderItems] = useState([])

    // Kitchen
    const [formData, setFormData] = useState({})
    const [kitchenButtons, setKitchenButtons] = useState(3)
    const [staffs, setStaffs] = useState([])
    const [tables, setTables] = useState([])

    const [filterData, setFilterData] = useState({ category: null, name: null })

    useEffect(() => {
        const handleEvent = () => {
            clearTimeout(timer);
            setTimer(setTimeout(() => {
                // Your logic here
                if (searchRef && searchRef.current) {
                    searchRef.current.focus();
                } else {
                    console.log('Timeout reached!', searchRef);
                }
            }, 5000));
        };

        // Attach event listeners
        document.addEventListener('mousemove', handleEvent);
        document.addEventListener('keyup', handleEvent);
        document.addEventListener('keypress', handleEvent);

        // Cleanup: Remove event listeners on component unmount
        return () => {
            document.removeEventListener('mousemove', handleEvent);
            document.removeEventListener('keyup', handleEvent);
            document.removeEventListener('keypress', handleEvent);
        };
    }, [timer]);
    /*
      */

    useEffect(() => {
        db = initializeDb();
    }, [])

    // Store
    const [store, setStore] = useState({
        branches: [
            {
                address: '',
                phone: '',
                disclaimer: '',
                website: '',
            }
        ]
    })

    const [cartSummaryTotal, setCartSummaryTotal] = useState(0)
    const [discount, setDiscount] = useState(0)
    const [loadScreen, setLoadScreen] = useState(false)
    // For spin
    const [loading, setLoading] = useState(true);

    // Store Settings
    const [posSetting, setPosSetting] = useState({
        print_receipt: 'thermal',
        editable_unit_price: false,
        enable_discount: false,
        enable_item_discount: false,
        cart_parking: false,
        verify_quantity: false,
        auto_adjust_quantity: false
    });
    const [srb, setSrb] = useState({})
    const [manageInventory, setManageInventory] = useState({ active: false })

    // Refresh syncing
    const [refreshSync, setRefreshSync] = useState(false)

    const onFinish = async (values) => {
        if (actionType === 'holdItems') {
            let data = {
                description: values.description,
                customerId: customerId,
                cartItems: items,
                cashRegisterId: cashRegisterId,
            }

            const response = await saveParkSale(data)
            setShowModal(false)
            if (response) {
                loadResumedData();
                emptyCart()
                setItemsPercentageDiscount('')
                setItemsAmountDiscount('');
                setShippingCharges('')
                setCustomerId('')
                setKitchenButtons(3)
                setFormData({})
                notification.open({ message: 'Items has been holded', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to hold items', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        } else {
            const response = await saveCustomer(values)
            if (response) {
                setCustomers([...customers, { ...values, id: response.id }])
                console.log(response, [...customers, { ...values, id: response.id }])
                setCustomerId(response.id);
                setShowModal(false);
                notification.open({ message: 'Customer has been added successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to add customer', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        }
    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const handleSearch = async (value, type) => {

        if (type == 1) {
            setFilterData({ category: 0, name: value })
        } else if (type == 2) {
            setFilterData({ name: null, category: value })
        }

        getProductsFromWebSql();
    }

    useDebounce(handleSearch, 2000, [filterData.name, filterData.category])

    const getCustomer = () => {
        let data = customers.find(i => i.id === customerId)
        if (data) {
            setCustomer(data)
        } else {
            setCustomer({ name: "Anonymous", phone: "N/A", balance: 0 })
        }
    }

    useEffect(() => {
        getCustomer()
    }, [customerId])

    const openFormModal = (type) => {
        setActionType(type)
        if (customer.name !== "Anonymous")
            form.setFieldsValue({ description: customer.name + ' ' + customer.phone })
        setShowModal(true)
    }

    const openModal = (type) => {
        setActionType(type)
    }

    const resumeShortcut = () => {
        setActionType('resume');
        setShowConfirmModal(true)
    }

    const holdShortcut = () => {
        setActionType('holdItems');
        setShowModal(true)
    }

    const invoiceShortcut = () => {
        setShowInvoiceModal(true);
    }

    const discardShortcut = () => {
        discardItems()
    }

    const printShortcut = () => {
        printOrder()
    }

    const sellShortcut = () => {
        setShowSellModal(true)
    }

    const loadResumedData = async () => {
        let data = await getAllParkSale(1)
        if (data) {
            setResumedData(data)
        }
    }

    const loadStore = async (id) => {
        const data = await getStore(id)
        if (data) {
            setStore(data)
            let branchID = parseInt(localStorage.getItem('posBranchId'))
            const branch = data.branches.find(i => i.id === branchID)
            setBranch(branch)
        }
    }

    const renderCartProducts = async (products) => {

        truncateProductCombo(db);
        truncateProducts(db);

        const productRows = [];
        const comboRows = [];



        for (let item of products) {

            if (item.show_in_pos) {

                let stock = 0;

                let cartItem = items.find(i => i.product_id === item.product_id)

                if (cartItem && cartItem.stock > 0) {
                    stock = cartItem.stock - 1 // for adjustment
                } else {
                    stock = item.productQuantity
                }

                const row = {
                    id: item.id,
                    product_id: item.product_id,
                    name: item.product_name,
                    price: manageInventory.active && !item.is_combo && !item.unlimited_stock ? item.retail_price : item.selling_price,
                    stock: stock,
                    image: item.image ? item.image : null,
                    isCombo: item.is_combo ? 1 : 0,
                    category_id: item.category_id,
                    cost_price: item.cost_price,
                    unlimited_stock: item.unlimited_stock ? 1 : 0,
                    sku: item.sku,
                    available_stock: item.productQuantity
                };


                //For Simple
                productRows.push(row);

                // For Combo
                if (row.isCombo) {

                    for (const combo of item.product_combo) {

                        let comboRow = {
                            product_id: combo.product_id,
                            item_id: combo.item_id,
                            deal_price: combo.deal_price,
                            quantity: combo.quantity
                        }

                        comboRows.push(comboRow)
                    }

                }
            }
        };

        if (productRows.length > 0) {
            await bulkInsertProducts(db, productRows, false)
        }

        if (comboRows.length > 0) {
            await bulkInsertProducts(db, comboRows, false)
        }

        getProductsFromWebSql();
    }

    const getProductsFromWebSql = async () => {

        setLoading(true)
        // setProducts([])

        const { category, name } = filterData;
        let response = [];

        if (category > 0) {
            response = await filterProductsByCategoryID(db, category)
        } else if (name != null && name != '') {
            response = await filterProductsByKeyword(db, name)
            // }else if(featured > 0){
            //     response = await getAllProducts(db)
        } else {
            response = await getProductsByProductID(db, featured);
        }

        setProducts(response)
        setLoading(false)

        // let productWithCombo = []

        // for(let i in response){
        //     if (i.isCombo === 1) {
        //         let combo = await getAllProductCombo(db)
        //         let mergeCombo = combo.filter(x => x.product_id === i.product_id)
        //         i.productCombo = mergeCombo;
        //     }
        //     productWithCombo.push(i)
        // }

        // console.log('productWithCombo',productWithCombo)

        // const uniqueData = Array.from(
        //     productWithCombo.reduce((map, item) => map.set(item.product_id, item), new Map()).values()
        // );

        // console.log('uniqueData',uniqueData)

        // setProducts(uniqueData)
        // setLoading(false)
    }

    const loadStoreSettings = async () => {
        const response = await getStoreSettings();

        if (response) {
            const posFeature = response.find(i => i.slug === ControlSettings.ENABLE_POS);
            const srbFeature = response.find(i => i.slug === ControlSettings.SRB_SETTINGS);
            const manageInventoryFeature = response.find(i => i.slug === ControlSettings.MANAGE_INVENTORY);

            if (posFeature) {
                const posSettingData = posFeature.setting ? JSON.parse(posFeature.setting) : null;
                if (posSettingData) {
                    setPosSetting(prev => {
                        return { ...prev, ...posSettingData };
                    })
                };
            }

            if (srbFeature) {
                const srbData = { active: response.active ?? false, settings: response.setting ? JSON.parse(response.setting) : null }
                setSrb(srbData)
            }

            if (manageInventoryFeature) {
                setManageInventory({ active: manageInventoryFeature.active });
            }
        }

        loadFeatured()
    }

    const loadFeatured = async () => {
        const response = await getFeatured();
        setFeatured(response)
    }

    useEffect(() => {
        initialize()
    }, [featured.length])

    const initialize = async () => {

        loadStoreSettings()

        if (!cashRegisterId) {
            history.push({ pathname: "/cash-register", search: '?sales-screen' });
        }

        getCustomer()
        loadStore(store_id)

        // Resumed
        loadResumedData()
        // Customers
        socketHandler('customers', null, (response) => {
            setCustomers(response)
        });
        // Products
        if (isOnline) {
            socketHandler('branch-products', { branch_id: branch_id }, (response) => {
                if(response){
                    renderCartProducts(response)
                }
            });

        } else {
            getProductsFromWebSql()
        }
        // staffs
        // socketHandler('staffs', null, (response) => {
        //     const waiters = response.filter(i => i.role === 'Waiter')
        //     setStaffs(waiters)
        // });
        // tables
        // socketHandler('tables', null, (response) => {
        //     setTables(response)
        // });

        setLoadScreen(false)
    }

    useEffect(() => {
        if (isOnline) {
            setRefreshSync(true)
            sync(db, setRefreshSync)
        }
    }, [isOnline])

    const resumed = async (id, cartData, customer_id) => {
        setItems(JSON.parse(cartData))
        setShowConfirmModal(false)
        setCustomerId(customer_id)
        let response = await updateParkSale(id, { resumed: 0 })
        if (response)
            loadResumedData()
    }

    const discardItems = () => {

        updateStockFromAvailableStock(db, items)

        const comboInCart = localStorage.getItem('comboInCart') ?? false
        if (comboInCart) localStorage.removeItem('comboInCart')


        emptyCart()
        setItemsPercentageDiscount(0)
        setItemsAmountDiscount(0)
        setShippingCharges(0)
        setShowConfirmModal(false)
        setCustomerId(0)
        setKitchenButtons(3)
        setFormData({})
        setChildValue(1)
        setParentChecked(false)
        getProductsFromWebSql()
    }

    const openEditItemModal = (id) => {
        let item = items.find(i => i.id == id)
        setCartItem(item)
        setShowEditItemModal(true)
    }

    const renderChildComponents = () => {
        if (items != '') {
            return (
                <>
                    {/* Child */}
                    <CartItems>
                        {
                            items.map((item, index) => (
                                <CartItemsRow
                                    item={item}
                                    openModal={openEditItemModal}
                                    products={products}
                                    db={db}
                                    getProductsFromWebSql={getProductsFromWebSql}
                                    editableUnitPrice={posSetting.editable_unit_price}
                                    key={index}
                                />
                            ))
                        }

                        <CartItemsSummery
                            amountDiscount={itemsAmountDiscount}
                            setAmountDiscount={setItemsAmountDiscount}
                            percentageDiscount={itemsPercentageDiscount}
                            setPercentageDiscount={setItemsPercentageDiscount}
                            shippingCharges={shippingCharges}
                            setShippingCharges={setShippingCharges}
                            setNotes={setNotes}
                            subtotal={cartSubtotal}
                            setSubtotal={setCartSubtotal}
                            cartSummaryTotal={cartSummaryTotal}
                            setCartSummaryTotal={setCartSummaryTotal}
                            setDiscount={setDiscount}
                            enableDiscount={posSetting.enable_discount}
                        />

                    </CartItems>

                    <CartFooter
                        holdModal={holdShortcut}
                        sellModal={sellShortcut}
                        printOrder={printShortcut}
                        cartSummaryTotal={cartSummaryTotal}
                        enableCartParking={posSetting.cart_parking}
                    />
                </>
            )
        }
        else {
            return (
                <EmptyCartView />
            )
        }
    }

    const printOrder = () => {
        let row = [];

        items.map(item => {
            let obj = {
                product_name: item.name,
                selling_price: item.price,
                quantity: item.quantity,
                discount: item.discountRow,
                total: item.discountRow ? item.itemTotal - item.discountRow : item.itemTotal
            };
            row.push(obj);
        });

        let summery = {
            subtotal: cartSubtotal,
            shipping: shippingCharges,
            discount: discount,
            total: cartSubtotal - shippingCharges - discount,
        }

        setSaleOrderItems(row)
        setSaleOrder(summery)
        setShowSalePrintModal(true)
    }

    return (
        <>
            <Row>
                {/* Product Section */}
                <Col span={12}>
                    <Spin tip="syncing..." spinning={refreshSync} indicator={<LoadingOutlined style={{ fontSize: 24, }} spin />}>
                        <Products
                            products={products}
                            db={db}
                            getProductsFromWebSql={getProductsFromWebSql}
                            loading={loading}
                            searchRef={searchRef}
                            displayProducts={displayProducts}
                            branch_id={branch_id}
                        >
                            <Categories search={handleSearch} />
                        </Products>
                    </Spin>
                </Col>

                {/* Cart Section */}
                <Col span={12}>
                    <Cart
                        modal={openModal}
                        count={resumedData.length}
                        enableCartParking={posSetting.cart_parking}
                        discard={discardShortcut}
                        resumeModal={resumeShortcut}
                        invoiceShortcut={invoiceShortcut}
                    >
                        {/* Customers */}
                        <Customers customers={customers} customerId={customerId} setId={setCustomerId} openModal={openFormModal} />
                        {/* Cart Childs */}
                        {renderChildComponents()}
                    </Cart>
                </Col>
            </Row>

            {/* Modals */}
            <FormModal
                type={actionType}
                visible={showModal}
                toggle={setShowModal}
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}>
                {/* child */}
                <FormModalFields type={actionType} />
            </FormModal>

            <ResumedDiscardModal
                type={actionType}
                visible={showConfirmModal}
                toggle={setShowConfirmModal}
                discardCart={discardItems}>
                {/* child */}
                <ResumedDiscardFields type={actionType} resumedCart={resumed} resumedItems={resumedData} />
            </ResumedDiscardModal>

            <EditCartItemModal
                visible={showEditItemModal}
                toggle={setShowEditItemModal}
                item={cartItem}
                checkbox={parentChecked}
                setCheckbox={setParentChecked}
                radio={childValue}
                setRadio={setChildValue}
                enableItemDiscount={posSetting.enable_item_discount}
            />

            <SellModal
                visible={showSellModal}
                toggle={setShowSellModal}
                customer={customer}
                customerId={customerId}
                shipping={parseInt(shippingCharges)}
                notes={notes}
                salePrintModal={setShowSalePrintModal}
                saleOrder={setSaleOrder}
                saleOrderItems={setSaleOrderItems}
                subtotal={cartSubtotal}
                setCustomerId={setCustomerId}
                cartSummaryTotal={cartSummaryTotal}
                branch_id={branch_id}
                discount={discount}
                setDiscount={setDiscount}
                setAmountDiscount={setItemsAmountDiscount}
                setPercentageDiscount={setItemsPercentageDiscount}
                setShippingCharges={setShippingCharges}
                setNotes={setNotes}
                setLoadScreen={setLoadScreen}
                store={store}
                db={db}
                srb={srb}
            />

            <SalePrintModal
                visible={showSalePrintModal}
                toggle={setShowSalePrintModal}
                order={saleOrder}
                items={saleOrderItems}
                componentRef={componentRef}
                store={store}
                customer={customer}
                products={products}
                posPrintReceipt={posSetting.print_receipt}
                srbToggle={srb.active}
                branch={branch}
            />

            <PreviousInvoiceModal
                visible={showInvoiceModal}
                toggle={setShowInvoiceModal}
                printModal={setShowSalePrintModal}
                setOrder={setSaleOrder}
                setItems={setSaleOrderItems}
                loadScreen={loadScreen}
            />

            <DineInModal
                visible={dineInModal}
                toggle={setDineInModal}
                setFormData={setFormData}
                orderInfo={formData}
                buttonType={kitchenButtons}
                waiters={staffs}
                tables={tables}
            />

            <DeliveryModal
                visible={deliveryModal}
                toggle={setDeliveryModal}
                setFormData={setFormData}
                orderInfo={formData}
                buttonType={kitchenButtons}
            />

            <TakeawayModal
                visible={takeawayModal}
                toggle={setTakeawayModal}
                setFormData={setFormData}
                orderInfo={formData}
                buttonType={kitchenButtons}
            />

        </>
    )

}

export default windowSize(SalesScreen) 