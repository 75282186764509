import { Button, Col, Row } from "antd";
import { useEffect, useState } from "react";

const CartFooter = ({ holdModal, sellModal, printOrder, cartSummaryTotal, enableCartParking }) => {
    const [currency,setCurrency] = useState('')

    useEffect(() => {
        setCurrency(localStorage.getItem('currency'))
    },[])

    return (
        <Row gutter={16} style={{ marginTop: 6 }}>
            <Col span={enableCartParking ? 6 : 8}>
                <Button style={{ width: '100%', background: '#666652', color: 'white' }}>
                    {currency} {(cartSummaryTotal).toFixed(2)}
                </Button>
            </Col>

            <Col span={enableCartParking ? 6 : 8}>
                <Button style={{ width: '100%', background: '#36ad56', color: 'white' }} onClick={() => sellModal()}>Sell (s)</Button>
            </Col>

            {
                enableCartParking &&
                <Col span={6}>
                    <Button style={{ width: '100%', background: '#adab36', color: 'white' }} onClick={holdModal}>Hold (Ctrl + h)</Button>
                </Col>
            }

            <Col span={enableCartParking ? 6 : 8}>
                <Button style={{ width: '100%', background: '#003366', color: 'white' }} onClick={printOrder}>Print (Ctrl + p)</Button>
            </Col>
        </Row>
    )
}
export default CartFooter