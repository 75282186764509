import { Button, Col, Row } from "antd"
import moment from "moment"

const ResumedDiscardFields = ({ type, resumedCart, resumedItems }) => {
    if (type == 'discard') {
        return "Are you sure you want to discard these items?"
    }
    else {
        return (
            <>
                <Row style={{ backgroundColor: '#f7f3f2', padding: '15px 20px' }} key="row">
                    <Col span={10}>Description</Col>
                    <Col span={1}></Col>
                    <Col span={8}>Date</Col>
                    <Col span={1}></Col>
                    <Col span={4}>Action</Col>
                </Row>
                {resumedItems.map((item, index) => (
                    <Row style={{ padding: '15px 20px' }} key={index}>
                        <Col span={10}>{item.description}</Col>
                        <Col span={1}></Col>
                        <Col span={8}>{moment(item.date).format('D-MMM-Y')}</Col>
                        <Col span={1}></Col>
                        <Col span={4}>
                            <Button
                                style={{ background: '#003366', color: 'white' }}
                                onClick={() => resumedCart(item.id, item.cart_items, item.customer_id)}>
                                Resume
                            </Button>
                        </Col>
                    </Row>
                ))

                }
            </>
        )
    }
}

export default ResumedDiscardFields