import { Form, Input } from "antd"

const FormModalFields = ({ type }) => {
    if (type === 'holdItems') {
        return (
            <>
                <Form.Item
                    label="Description"
                    name='description'
                    labelCol={{ span: 24 }}
                    rules={[
                        {
                            required: true,
                            message: 'Description is required',
                        },
                    ]}>
                    <Input />
                </Form.Item>
            </>
        )
    } else {
        return (
            <>
                <Form.Item
                    label="Customer Name"
                    name='name'
                    labelCol={{ span: 24 }}
                    rules={[
                        {
                            required: true,
                            message: 'Customer name is required',
                        },
                    ]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Phone Number"
                    name='phone'
                    labelCol={{ span: 24 }}
                    rules={[
                        {
                            required: true,
                            message: 'Phone Number is required',
                        },
                    ]}>
                    <Input type="number" min={0} placeholder="03000000000" />
                </Form.Item>
            </>
        )
    }
}

export default FormModalFields