import { Button, Checkbox, Col, Divider, Form, Input, Modal, Radio, Row, Typography } from "antd"
import TextArea from "antd/lib/input/TextArea"
import { useEffect, useState } from "react";
import { useCart } from "react-use-cart";

const { Text } = Typography

const EditCartItemModal = ({ visible, toggle, item, checkbox, setCheckbox, radio, setRadio, enableItemDiscount }) => {

    const { updateItem, getItem } = useCart();

    const [form] = Form.useForm();

    const [percentage, setPercentage] = useState(0);
    const [perItem, setPerItem] = useState(0);
    const [perUnit, setPerUnit] = useState(0);
    const [notes, setNotes] = useState('');

    const rowDiscount = async () => {
        updateItem(item.id, { notes: notes })
        if (radio === 1) {
            updateItem(item.id, { discountRow: percentage, percentage: true, perUnit: false })
        }
        else if (radio === 2) {
            updateItem(item.id, { discountRow: perItem, percentage: false, perUnit: false })
        } else {
            updateItem(item.id, { discountRow: perUnit, percentage: false, perUnit: true })
        }
        form.resetFields()
        toggle(false)
    }

    useEffect(() => {

        const defaultData = getItem(item.id)

        if (defaultData) {
            setRadio(defaultData.percentage ? 1 : (defaultData.perUnit ? 3 : (!defaultData.perUnit && !defaultData.percentage ? 2 : 1)))
            form.setFieldsValue({
                notes: defaultData.notes,
                percentage: defaultData.percentage ? defaultData.discountRow : null,
                perItem: !defaultData.perUnit && !defaultData.percentage ? defaultData.discountRow : null,
                perUnit: defaultData.perUnit ? defaultData.discountRow : null
            })
        }

    }, [item])


    return (
        <Form onFinish={rowDiscount} form={form}>
            <Modal
                width={"45%"}
                title='Edit Cart Item'
                centered
                open={visible}
                onCancel={() => { toggle(false) }}
                footer={[
                    <Button key="save" type="primary" onClick={form.submit}>
                        Apply
                    </Button>,
                    <Button key="back" onClick={() => { toggle(false) }}>
                        Close
                    </Button>
                ]}>
                <Row>
                    <Col span={14}>
                        <Text>{item.name}</Text>
                    </Col>
                    <Col span={9}>
                        {item.quantity}
                    </Col>
                    <Divider />
                    <Col span={14}>
                        <Text>Unit Price : {item.price}</Text>
                    </Col>
                    {/* <Col span={9}>
                    Subtotal : {item.discountRow ? item.itemTotal - item.discountRow : item.itemTotal}
                </Col> */}
                    <Divider />
                    {
                        enableItemDiscount &&
                        <>
                            <Col span={24}>
                                <Form.Item name="allow_discount"></Form.Item>
                                <Checkbox
                                    checked={checkbox}
                                    onChange={(e) => setCheckbox(e.target.checked)}
                                >
                                    Allow Discount (Space)
                                </Checkbox>
                            </Col>
                            <Divider />
                            <Col span={24}>
                                <Radio.Group
                                    value={radio}
                                    style={{ width: '100%' }}
                                    onChange={(e) => { setRadio(e.target.value); }}
                                    disabled={!checkbox}
                                >
                                    <Row>
                                        <Col span={14}>
                                            <Radio value={1}>By Percentage</Radio>
                                        </Col>
                                        <Col span={9}>
                                            <Form.Item name="percentage">
                                                <Input type="number" onChange={(e) => setPercentage(e.target.value)} disabled={radio == 1 && checkbox ? false : true} />
                                            </Form.Item>
                                        </Col>
                                        <Divider />

                                        <Col span={14}>
                                            <Radio value={2}>On Item Total</Radio>
                                        </Col>
                                        <Col span={9}>
                                            <Form.Item name="perItem">
                                                <Input type="number" onChange={(e) => setPerItem(e.target.value)} step={0.01} disabled={radio == 2 && checkbox ? false : true} />
                                            </Form.Item>
                                        </Col>
                                        <Divider />

                                        <Col span={14}>
                                            <Radio value={3}>On Per Unit</Radio>
                                        </Col>
                                        <Col span={9}>
                                            <Form.Item name="perUnit">
                                                <Input type="number" onChange={(e) => setPerUnit(e.target.value)} step={0.01} disabled={radio == 3 && checkbox ? false : true} />
                                            </Form.Item>
                                        </Col>
                                        <Divider />
                                    </Row>
                                </Radio.Group>
                            </Col>
                        </>
                    }

                    <Col span={24}>
                        <Form.Item name="notes" label="Notes" labelCol={{ span: 24 }}>
                            <TextArea onChange={(e) => setNotes(e.target.value)} key={item.notes}></TextArea>
                        </Form.Item>
                    </Col>

                </Row>
            </Modal>
        </Form>
    )
}
export default EditCartItemModal