
function calculatePossibleStock(stock, comboItems) {
    let possibleStock = Number.MAX_SAFE_INTEGER

    comboItems.map(combo => {
        if (stock.hasOwnProperty(combo.item_id)) {
            possibleStock = Math.min(possibleStock, Math.floor(stock[combo.item_id] / parseFloat(combo.quantity)));
        } else {
            return 0; // If any product is not available, combo stock is 0
        }
    })

    return possibleStock;
}

export default function ComboStockCalculator(comboProducts, products) {

    comboProducts.forEach((product) => {

        // const comboArrays = comboProducts.flatMap((product) => product.productCombo || []);

        const comboItems = product.productCombo.map((item) => ({ item_id: item.item_id, quantity: item.quantity }));

        const items = products.filter((item) =>
            comboItems.some((comboItem) => comboItem.item_id === item.product_id)
        );

        const productIDs = items.map((item) => item.product_id);
        const quantities = items.map((item) => item.stock);

        const stock = items.reduce((acc, item, index) => {
            acc[item.product_id] = quantities[index];
            return acc;
        }, {});

        product.productQuantity = calculatePossibleStock(stock, comboItems);
    });

    return comboProducts;

}