
import React, { useState, useEffect, useCallback } from 'react';
import { Col, notification, Typography, Row, Divider, Table, Button, Dropdown, Menu, Card } from 'antd';
import { useParams, useHistory } from "react-router-dom";
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { getOrder, updateStatus } from '../../data';
import windowSize from 'react-window-size';
import OrderStatus from '../../constant/orderStatus';

const { Text, Title } = Typography

const OrderForm = ({ windowWidth }) => {

  const status = Object.values(OrderStatus);

  const [order, setOrder] = useState({})
  const [dataSource, setDataSource] = useState([])
  const [currency, setCurrency] = useState('')
  const isMobile = windowWidth <= 768

  let { id } = useParams()
  let history = useHistory()

  const columns = [

    {
      title: 'Item',
      dataIndex: 'product_name',
      key: 'item',

    },
    {
      title: 'Price',
      dataIndex: 'total',
      key: 'price',
      align: 'right',
      render: text => (
        <Text >
          {currency} {text}
        </Text>
      ),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'right',

    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      align: 'right',
      render: text => (
        <Text >
          {currency} {text}
        </Text>
      ),
    },

  ];
  const changeStatus = async (status) => {
    let data = {
      id,
      status
    }
    const response = await updateStatus(id, data);
    if (response) {
      notification.open({ message: 'Order status has been  updated', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
      window.location.reload()
    } else {
      notification.open({ message: 'Unable to update order status...', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })

    }
  }
  const loadData = useCallback(async (id) => {
    if (id > 0) {
      let data = await getOrder(id);
      let { orderItems, order } = data
      if (data != null) {
        setOrder(order)
        setDataSource(orderItems);
      }
    }
  }, [])

  useEffect(() => {
    loadData(id);

    const branch_id = localStorage.getItem('branch_id');
    let stores = JSON.parse(localStorage.getItem('stores'))
    if(stores && stores.length > 0){
      let branch = stores[0].branches.find(b => b.id === branch_id);
      setCurrency(branch.currency)
    }

  }, []);

  const renderOredrView = () => {
    return (
      <div style={{ background: '#fff', padding: '20px' }}>
        <div className='flex'>
          <Title level={3}>Order {order.order_number}</Title>
          {order.status == OrderStatus.COMPLETED && <Button type='primary' onClick={() => history.push({ pathname: "/orders/print/" + id })}>Print</Button>}
        </div>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Title level={4} >Order Details</Title>
            <div className='flexItem' >
              <Text>Order Number</Text>
              <Text>{order.order_number}</Text>
            </div>
            <div className='flexItem'>
              <Text>Order Date</Text>
              <Text>{order.date}</Text>
            </div>
            <div className='flexItem'>
              <Text>Order status</Text>
              <Dropdown overlay={
                <Menu >
                  {
                    status.slice(status.indexOf(order.status) + 1).map((status, index) => (
                      <Menu.Item onClick={() => changeStatus(status)} key={index}>{status}</Menu.Item>
                    ))
                  }
                </Menu>
              }
                placement="bottomCenter">
                <Button value={order.status}>{order.status}</Button>
              </Dropdown>
            </div>
            <div className='flexItem'>
              <Text>Shipping Address</Text>
              <Text>{order.shipping_address}</Text>
            </div>
            <div className='flexItem'>
              <Title level={4}>Payment Method</Title>
              <Text>{order.payment_method}</Text>
            </div>
          </Col>
          <Col span={12}>
            <Title level={4}>Customer Information</Title>
            <div className='flexItem' >
              <Text>Order Name</Text>
              <Text>{order.name}</Text>
            </div>
            <div className='flexItem' >
              <Text>Email</Text>
              <Text>{order.email}</Text>
            </div>
            <div className='flexItem'>
              <Text>Phone</Text>
              <Text>{order.phone}</Text>
            </div>
          </Col>
        </Row>
        <Row>
          <Title level={3}>Invoice Discription</Title>
          <Divider className='divide' />
        </Row>
        <Table
          pagination={false}
          dataSource={dataSource}
          columns={columns}
        />
        <div className='flexItem' >
          <Text>Subtotal</Text>
          <Text>{currency} {order.subtotal}</Text>
        </div>
        <div className='flex' >
          <Text>Discount</Text>
          <Text>{currency} {order.discount}</Text>
        </div>        
        <div className='flex' >
          <Text>Shipping Charges</Text>
          <Text> {currency} {order.shipping}</Text>
        </div>
        <div className='flexItem' >
          <Text>Total</Text>
          <Text>{currency}   {order.total}</Text>
        </div>
      </div>

    );
  }

  const renderOredrViewMobile = () => {
    return (
      <>
        <Title level={3}>Order {order.order_number}</Title>
        <Card style={{ width: '100%' }}>
          <center><Title level={5}>Order Details </Title></center>
          <Divider />
          <Text style={{ fontWeight: 'bolder' }}>Order Number :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{order.order_number}</span></Text>
          <br />
          <Text style={{ fontWeight: 'bolder' }}>Order Date :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{order.date}</span></Text>
          <br />
          <Text style={{ fontWeight: 'bolder' }}>Order status :
            <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>
              <Dropdown overlay={
                <Menu >
                  <Menu.Item onClick={() => changeStatus('Confirm')} key="1">
                    Confirmed
                  </Menu.Item>
                  <Menu.Item onClick={() => changeStatus('Processing')} key="2">
                    Processing
                  </Menu.Item>
                  <Menu.Item onClick={() => changeStatus('  On The Way')} key="3">
                    On The Way
                  </Menu.Item>
                  <Menu.Item onClick={() => changeStatus('Complete')} key="4">
                    Complete
                  </Menu.Item>
                </Menu>
              }
                placement="bottomCenter">
                <Button value={order.status}>{order.status}</Button>
              </Dropdown>
            </span>
          </Text>
          <br />
          <Text style={{ fontWeight: 'bolder' }}>Shipping Address :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{order.shipping_address}</span></Text>
          <br />
          <Text style={{ fontWeight: 'bolder' }}>Payment Method :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{order.payment_method}</span></Text>
          <br /><br /><br />
          <center><Title level={5}>Customer Information </Title></center>
          <Divider />
          <Text style={{ fontWeight: 'bolder' }}>Order Name :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{order.name}</span></Text>
          <br />
          <Text style={{ fontWeight: 'bolder' }}>Email :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{order.email}</span></Text>
          <br />
          <Text style={{ fontWeight: 'bolder' }}>Phone :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{order.phone}</span></Text>
          <br />
        </Card>
        <br />
        <Card style={{ width: '100%' }}>
          <center><Title level={5}>Invoice Description </Title></center>
          {
            dataSource.map((invoice, index) => {
              return (
                <>
                  <Text style={{ fontWeight: 'bolder' }}>Serial No. :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{index + 1}</span></Text>
                  <br />
                  <Text style={{ fontWeight: 'bolder' }}>Item :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{invoice.product_name}</span></Text>
                  <br />
                  <Text style={{ fontWeight: 'bolder' }}>Price :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{currency} {invoice.total}</span></Text>
                  <br />
                  <Text style={{ fontWeight: 'bolder' }}>Quantity :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{invoice.quantity}</span></Text>
                  <br />
                  <Text style={{ fontWeight: 'bolder' }}>Total :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{currency} {invoice.total}</span></Text>
                  <Divider />
                </>
              )
            })
          }
          <Text style={{ fontWeight: 'bolder' }}>SubTotal :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{order.subtotal}</span></Text>
          <br />
          <Text style={{ fontWeight: 'bolder' }}>Shipping Charges :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{order.shipping}</span></Text>
          <br />
          <Text style={{ fontWeight: 'bolder' }}>Total :<span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{order.total}</span></Text>
          <br />
        </Card>
      </>
    )
  }

  const renderContent = () => {
    if (isMobile) {
      return renderOredrViewMobile()
    } else {
      return renderOredrView()
    }
  }

  return (
    <>
      {renderContent()}
    </>
  )

};


export default windowSize(OrderForm);