import { useEffect, useState } from "react"
import { Button, Col, Image, Modal, Row, Typography } from "antd"
import moment from "moment"
import QRCode from "react-qr-code"
import ReactToPrint from "react-to-print"
import { ajaxService } from "../../../services"
import useWebSocket from "../../../utility/use-websocket"
import { useOnlineStatus } from "../../../hooks"
import { getStoreSetting } from "../../../data"
import ControlSettings from "../../../constant/controlSettings"
import { formatTime } from "../../../utility"
import { useHotkeys } from 'react-hotkeys-hook';

const { Text } = Typography

const SalePrintModal = ({ visible, toggle, order, items, branch , componentRef, store, customer, products, posPrintReceipt, srbToggle }) => {

    const { socketHandler } = useWebSocket()
    const isOnline = useOnlineStatus();
    const [displayBranchInfo, setDisplayBranchInfo] = useState(false);

    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [website, setWebsite] = useState('');
    const [disclaimer, setDisclaimer] = useState('');
    const [openingTime, setOpeningTime] = useState('');
    const [closingTime, setClosingTime] = useState('');
    const [displayBranchAddress, setDisplayBranchAddress] = useState(false);
    // useHotkeys('enter', () => handlePrint())  

    
    useEffect(() => {
        const getSetting = async () => {
            try {
                // const branchSettingResponse = await getStoreSetting(ControlSettings.RECEIPT_FORMAT);
                // if (branchSettingResponse && branchSettingResponse.setting) {
                //     let { display_branch_info } = JSON.parse(branchSettingResponse.setting);
                //     setDisplayBranchInfo(display_branch_info)
                // }

                const posSettings = await getStoreSetting(ControlSettings.ENABLE_POS);
                if (posSettings && posSettings.setting) {
                    let { display_branch_address } = JSON.parse(posSettings.setting);
                    setDisplayBranchAddress(display_branch_address)
                }
            } catch (e) {
                // setDisplayBranchInfo(false)
                // console.log('Not loaded')
            }
        }

        getSetting();

    }, [products.length])

    useEffect(() => {
        setName(store.name)

        if (branch) {
            if (displayBranchAddress) {
                setName(branch.name)
            }
            setOpeningTime(formatTime(branch.opening_time))
            setClosingTime(formatTime(branch.closing_time))
            setDisclaimer(branch.disclaimer)
            setPhone(branch.phone)
            setAddress(branch.address)
            setWebsite(branch.website)
        }
    }, [order, displayBranchAddress, branch])

    const renderCombo = (row) => {
        let product = products.find(i => i.id === row.product_id);
        if (product && product.is_combo) {
            return ("(Combo)")
            //     return (
            //         product.product_combo.map(i => {
            //             let p = products.find(obj => obj.id === i.item_id)
            //             return (
            //                 <Row>
            //                     <Col span={12}>
            //                         <Text style={{ color: '#00CC66' }}>{p ? p.name : null}</Text>
            //                     </Col>
            //                     <Col span={4}>
            //                         <Text style={{ color: '#00CC66' }}>{i.quantity}</Text>
            //                     </Col>
            //                 </Row>
            //             )
            //         })
            //     )
        }
    }

    const renderItems = (type) => {
        if (type == "thermal") {
            return (
                <>
                    <Row style={{ padding: '0px 10px 0px 5px' }}>
                        <Col span={8} style={{ textAlign: 'center' }}>
                            <b>Price</b>
                        </Col>
                        <Col span={8} style={{ textAlign: 'center' }}>
                            <b> Quantity</b>
                        </Col>
                        <Col span={8} style={{ textAlign: 'right' }}>
                            <b>Total Price</b>
                        </Col>
                    </Row>
                    <hr />

                    {
                        items.map(item => (
                            <>
                                <Row gutter={16} style={{ padding: '0px 10px 0px 5px' }}>
                                    <Col span={24}>{item.product_name} {renderCombo(item)}</Col>
                                    <Col span={24}>
                                        <hr style={{ opacity: 0.4 }} />
                                    </Col>
                                    <Col span={8} style={{ textAlign: 'right' }}>{item.selling_price}</Col>
                                    <Col span={8} style={{ textAlign: 'center' }}>{item.quantity}</Col>
                                    <Col span={8} style={{ textAlign: 'right' }}>{item.total}</Col>
                                </Row>
                                <hr style={{ opacity: 0.7 }} />
                            </>
                        ))
                    }
                </>
            )
        }
        else {
            return (
                <>
                    <Row style={{ padding: '0px 10px 0px 5px' }}>
                        <Col span={7}>
                            <b>Item</b>
                        </Col>
                        <Col span={4} style={{ textAlign: 'center' }}>
                            <b> Price</b>
                        </Col>
                        <Col span={5}>
                            <b>Quantity</b>
                        </Col>
                        <Col span={4} style={{ textAlign: 'center' }}>
                            <b>Discount</b>
                        </Col>
                        <Col span={4} style={{ textAlign: 'center' }}>
                            <b>Total</b>
                        </Col>
                    </Row>
                    <hr />

                    {
                        items.map(item => (
                            <>
                                <Row gutter={16} style={{ padding: '0px 10px 0px 5px' }}>
                                    <Col span={7}>{item.product_name} {renderCombo(item)}</Col>
                                    <Col span={4} style={{ textAlign: 'right' }}>{item.selling_price}</Col>
                                    <Col span={5} style={{ textAlign: 'center' }}>{item.quantity}</Col>
                                    <Col span={4} style={{ textAlign: 'right' }}>{item.discount ? item.discount : 0}</Col>
                                    <Col span={4} style={{ textAlign: 'right' }}>{item.total}</Col>
                                </Row>
                                {/* {renderCombo(item)} */}
                                <hr style={{ opacity: 0.7 }} />
                            </>
                        ))
                    }
                </>
            )
        }
    }

    const renderSrbRows = (
        <>
            <Col span={15}>Total Befor Tax</Col>
            <Col span={9} style={{ textAlign: 'right' }}>{order?.net_total ? order.net_total : 0}</Col>
            <Col span={12}>Tax {order?.tax_rate}%</Col>
            <Col span={12} style={{ textAlign: 'right' }}>{order?.net_total ? order.tax_amount : 0}</Col>
        </>
    )

    const renderContent = (type) => {
        let thermal = (type == "thermal")
        return (
            <>
                <div style={{ textAlign: 'center' }}>
                    {
                        !displayBranchAddress &&

                        <>
                            <Image
                                width={50}
                                src={store ? (store.logo ? ajaxService.getImage(store.logo) : null) : null}
                                preview={false}
                                alt="Store Logo"
                            />
                            <br />

                        </>
                    }
                    <Text>Order #{order.order_number || 'N/A'}</Text>
                    <br />
                    <Text><b>{name}</b></Text>
                    <br />
                    <Text><b>{phone}</b></Text>
                    <br />
                    <Text><b>Shop Timing: {openingTime} - {closingTime}</b></Text>
                    <br />
                    <Text><b>{website}</b></Text>
                </div>

                <div className="themeBtn">
                    <p style={{ textAlign: 'center', padding: 3 }}>Receipt</p>
                </div>

                {/* Order Information */}
                <Row gutter={20}>

                    <Col span={thermal ? 13 : 6}><b>Customer</b></Col>
                    <Col span={thermal ? 10 : 6} style={{ textAlign: 'right' }}>{order ? (order.name ?? '') : (customer.name)}</Col>

                    <Col span={thermal ? 13 : 6}><b>Phone</b></Col>
                    <Col span={thermal ? 10 : 6} style={{ textAlign: 'right' }}>{order ? (order.phone ?? '') : (customer.phone)}</Col>

                    <Col span={thermal ? 13 : 6}><b>Order</b></Col>
                    <Col span={thermal ? 10 : 6} style={{ textAlign: 'right' }}>{order ? (order.order_number ?? '') : "N/A"}</Col>

                    <Col span={thermal ? 13 : 5}><b>Date & Time</b></Col>
                    <Col span={thermal ? 10 : 7} style={{ textAlign: 'right' }}>{order ? moment(order.date).format('DD-MMM-yyy hh:mm a') : "N/A"}</Col>
                </Row>

                {/* Order Items Heading */}
                <div style={{ padding: 10 }}>
                    {renderItems(type)}
                </div>

                <Row>
                    <Col span={24}>
                        <Row>
                            <Col span={12}>
                                <Row>
                                    {
                                        srbToggle &&
                                        <>
                                            <Col span={24}>
                                                <Image
                                                    width={thermal ? 70 : 50}
                                                    src={process.env.PUBLIC_URL + '/srb.png'}
                                                    preview={false}
                                                />
                                            </Col>
                                            {
                                                isOnline &&
                                                <Col span={10} style={{ marginTop: 5 }}>
                                                    <QRCode value={order?.invoice_id ?? 'Default Value'} style={{ height: thermal ? 70 : 50, width: thermal ? 70 : 50 }} />
                                                    <Text style={{ fontSize: 12 }}>{order?.invoice_id}</Text>
                                                </Col>
                                            }
                                        </>
                                    }
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row>

                                    <Col span={12}>Subtotal</Col>
                                    <Col span={12} style={{ textAlign: 'right' }}>{order.subtotal ? order.subtotal : ''}</Col>

                                    <Col span={12}>Shipping</Col>
                                    <Col span={12} style={{ textAlign: 'right' }}>{order.shipping ? order.shipping : 0}</Col>

                                    <Col span={12}>Discount</Col>
                                    <Col span={12} style={{ textAlign: 'right' }}>{order.discount ? order.discount : 0}</Col>

                                    {
                                        srbToggle &&
                                        renderSrbRows
                                    }

                                    <Col span={15}>Total {srbToggle ? "After Tax" : ''}</Col>
                                    <Col span={9} style={{ textAlign: 'right' }}>{order.total ? order.total : ''}</Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <div className="themeBtn">
                    <p style={{ textAlign: 'center', padding: 10 }}>Address</p>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <p><b>{address}</b></p>
                    <Text><b>{disclaimer}</b></Text>
                    <br />
                    <Text><b>Thankyou for shopping with us</b></Text>
                </div>
            </>
        )
    }

    return (
        <Modal
            width={"40%"}
            style={{ height: '98%' }}
            title='Print Screen'
            centered
            open={visible}
            onCancel={() => { toggle(false) }}
            footer={[
                <ReactToPrint
                    trigger={() => <Button key="save" type="primary">Print</Button>}
                    content={() => componentRef.current}
                />,
                <Button key="back" onClick={() => { toggle(false) }}>Close</Button>
            ]}>
            <div className="hide-scrollbar">
                <div ref={componentRef} style={{ margin: 10 }}>
                    {renderContent(posPrintReceipt)}
                </div>
            </div>
        </Modal>
    )
}
export default SalePrintModal