import Dexie from 'dexie';

let dbInstance = null;

export function initializeDb() {
    if (!dbInstance) {
        const db = new Dexie('pos');

        db.version(1).stores({
            product: `product_id,name,price,stock,available_stock,image,isCombo,category_id,cost_price,unlimited_stock,sku`,
            productCombo: `product_id,item_id,deal_price,quantity`,
            order: `,temporary_order_id,order_number,store_id,branch_id,status,date,name,phone,customer_id,subtotal,shipping,discount,total,notes,amount_changed,amount_paid,platform,cash_register_id,is_grocery_pos`,
            orderItem: `,cost_price,isCombo,itemTotal,order_id,product_id,name,price,quantity,rowDiscountAmount,rowSubtotal,stock,unlimited_stock`
        });

        console.log('Database initialized');

        dbInstance = db;
    }

    return dbInstance;
}

//Todo
export async function updateStockFromAvailableStock(db, products) {

    if (!db) {
        db = dbInstance
    }

    if (products.length > 0) {
        for(let key in products){
            let p = products[key]
            await db.product.update(p.product_id, { stock: p.available_stock })
        }
    }
}

//Todo
export async function updateProductAvailableStock(db, availableStock, productID) {

    if (!db) {
        db = dbInstance
    }

    await db.product.update(productID, { available_stock: availableStock });
}

export async function bulkInsertProducts(db, products, combo) {

    if (!db) {
        db = dbInstance
    }

    if (combo) {
        await db.product.bulkPut(products);
        console.log('Bulk insert combo')
    } else {
        await db.product.bulkPut(products);
        console.log('Bulk insert product')
    }
}

export async function insertProducts(db, product, combo) {

    if (!db) {
        db = dbInstance
    }

    if (combo) {

        let p = {
            product_id: product.product_id,
            item_id: product.item_id,
            deal_price: product.deal_price,
            quantity: product.quantity
        }

        await db.productCombo.add(p);
        console.log("combo added");

    } else {

        let p = {
            id: product.id,
            product_id: product.product_id,
            name: product.name,
            price: product.price,
            stock: product.stock,
            available_stock: product.stock,
            image: product.image,
            isCombo: product.isCombo,
            category_id: product.category_id,
            cost_price: product.cost_price,
            unlimited_stock: product.unlimited_stock,
            sku: product.sku
        }

        await db.product.add(p);
        console.log("product added");
    }
}

export async function getProduct(db, pid, combo) {

    if (!db) {
        db = dbInstance
    }

    let result;

    if (combo) {
        result = await db.productCombo.where("item_id").equals(pid).toArray();
    } else {
        result = await db.product.where("product_id").equals(pid).toArray();
    }

    return result;
}

export async function getProductBySKU(db, sku) {

    if (!db) {
        db = dbInstance
    }

    return await db.product.where("sku").equals(sku).toArray();
}

export async function getProductByProductID(db, id, combo) {

    if (!db) {
        db = dbInstance
    }

    let result;

    if (combo) {
        result = await db.productCombo.where("product_id").equals(id).toArray();
    } else {
        result = await db.product.where("product_id").equals(id).toArray();
    }

    if (result) {
        return result;
    } else {
        return null;
    }
}

export async function getProductsByProductID(db, ids) {
    if (!db) {
        db = dbInstance
    }

    return await db.product.filter(product => ids.includes(product.product_id)).toArray();
}

export async function getAllProducts(db) {

    if (!db) {
        db = dbInstance
    }

    if(db){
        let results = await db.product.toArray();
    
        if (results.length > 0) {
            return results;
        } else {
            return [];
        }
    }
    
    return [];
}

export async function getAllProductCombo(db) {

    if (!db) {
        db = dbInstance
    }

    let result = await db.productCombo.toArray();

    if (result.length > 0) {
        return result;
    } else {
        return []
    }
}

export async function updateProduct(db, product, product_id, combo) {

    if (!db) {
        db = dbInstance
    }
    

    if (combo) {
        await db.productCombo.update(product_id, { deal_price: product.deal_price, quantity: product.quantity });
        console.log("productcombo updated");
    } else {
        await db.product.update(product_id, { price: product.price, stock: product.stock });
        console.log("product updated");
    }
}

export async function removeProduct(db, id, combo) {

    if (!db) {
        db = dbInstance
    }

    if (combo) {
        await db.productCombo.delete(id);
        console.log("combo deleted");
    } else {
        await db.product.delete(id);
        console.log("product deleted");
    }
}

export async function truncateProducts(db) {

    if (!db) {
        db = dbInstance
    }

    await db.product.clear()
    console.log("all products deleted");
}

export async function truncateProductCombo(db) {

    if (!db) {
        db = dbInstance
    }

    await db.productCombo.clear()
    console.log("all product combos deleted");
}

export async function filterProductsByCategoryID(db,categoryId){

    if (!db) {
        db = dbInstance
    }

    let response =  await db.product.filter(p => p.category_id == categoryId).limit(20).toArray();

    return response;
}

export async function filterProductsByKeyword(db, keyword){

    if (!db) {
        db = dbInstance
    }

    let response = await db.product.where('name').anyOfIgnoreCase(keyword).or('name').startsWithIgnoreCase(keyword)
                                    .or('sku').anyOfIgnoreCase(keyword).or('sku').startsWithIgnoreCase(keyword)
                                    .limit(20)
                                    .toArray();
    // filter(p => p.name.includes(keyword) || p.sku.includes(keyword))


    console.log(response)

    return response;
}

export async function filterProducts(db, categoryId, keyword, featured) {

    if (!db) {
        db = dbInstance
    }

    let results;

    if (categoryId || keyword) {

        results = await db.product.toArray();

        if (categoryId > 0) {
            results = results.filter(r => r.category_id == categoryId);
        }

        if (keyword) {
            results = results.filter(r => r.name.match(keyword) || r.sku.match(keyword));
        }

    } else {

        if (featured.length > 0) {
            results = await db.product.filter(product => featured.includes(product.id)).toArray();
        } else {
            results = await db.product.toArray();
        }
    }

    return results;
}

export async function insertOrders(db, order) {

    if (!db) {
        db = dbInstance
    }

    let orderRow = {
        temporary_order_id: order.temporary_order_id,
        order_number: order.order_number,
        store_id: order.store_id,
        branch_id: order.branch_id,
        status: order.status,
        date: order.date,
        name: order.name,
        phone: order.phone,
        customer_id: order.customer_id,
        subtotal: order.subtotal,
        shipping: order.shipping,
        discount: order.discount,
        total: order.total,
        notes: order.notes,
        amount_changed: order.amount_changed,
        amount_paid: order.amount_paid,
        platform: order.platform,
        cash_register_id: order.cash_register_id,
        is_grocery_pos: order.is_grocery_pos
    }

    let lastInsertRowId = await db.order.add(orderRow);
    console.log("order added");
    return lastInsertRowId;
}

export async function getOrder(db, oid) {

    if (!db) {
        db = dbInstance
    }

    return await db.order.where("id").equals(oid).toArray();
}

export async function getAllOrder(db) {

    if (!db) {
        db = dbInstance
    }

    let results = await db.order.toArray();

    if (results.length > 0) {
        return results;
    } else {
        return [];
    }
}

export async function insertOrderItems(db, item) {

    if (!db) {
        db = dbInstance
    }

    let orderItem = {
        id: item.id,
        cost_price: item.cost_price,
        isCombo: item.isCombo,
        itemTotal: item.itemTotal,
        order_id: item.order_id,
        product_id: item.product_id,
        name: item.name,
        price: item.price,
        quantity: item.quantity,
        rowDiscountAmount: item.rowDiscountAmount,
        rowSubtotal: item.rowSubtotal,
        stock: item.stock,
        unlimited_stock: item.unlimited_stock,
    }

    await db.orderItem.add(orderItem);
    console.log("order item added");
}

export async function getOrderItems(db, orderID) {

    if (!db) {
        db = dbInstance
    }

    let results = await db.orderItem.where("order_id").equals(orderID).toArray();
    if (results.length > 0) {
        return results; // Pass the array of order items
    } else {
        return null; // Pass null if no items found
    }
}

export async function truncateOrders(db) {

    if (!db) {
        db = dbInstance
    }

    await db.order.clear()
    console.log("all orders deleted");
}

export async function truncateOrderItems(db) {

    if (!db) {
        db = dbInstance
    }

    await db.orderItem.clear()
    console.log("all orders deleted");
}