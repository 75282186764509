import { Image } from "antd";

const EmptyCartView = () => (
    <div style={{ textAlign: 'center', marginTop: 70 }}>
        <Image
            preview={false}
            width={200}
            src='/img/cart.png'
            style={{ opacity: 0.8 }}
        />
        <h3 style={{ marginLeft: 20, marginTop: 10 }}><b><i>Your Cart Is Empty</i></b></h3>
    </div>
)

export default EmptyCartView