import { Button, Form, Space, Col, Input, Modal, Row } from "antd"
import TextArea from "antd/lib/input/TextArea"
import { useEffect } from "react"

const DeliveryModal = ({ visible, toggle, setFormData, orderInfo, buttonType }) => {

    const [orderForm] = Form.useForm()

    useEffect(() => {
        if (buttonType === 0 || buttonType === 2 || buttonType === 3)
            orderForm.resetFields()
    }, [orderInfo])

    const onFinish = (values) => {
        const json = JSON.stringify(values)
        setFormData({ json, orderType: 1 })
        toggle(false)
    }

    return (
        <Modal
            width="60%"
            title="Delivery"
            centered
            open={visible}
            onCancel={() => toggle(false)}
            footer={[
                <Space key="space">
                    <Button type="primary" key="sbmit" onClick={orderForm.submit}> Submit </Button>
                    <Button key="back" onClick={() => toggle(false)}>
                        Close
                    </Button>
                </Space>
            ]} >
            <Form form={orderForm} onFinish={onFinish}>
                <Row gutter={16}>

                    <Col span={24}>
                        <Form.Item name="address" label="Address" labelCol={{ span: 24 }}
                            initialValue={orderInfo.address}
                            rules={[
                                {
                                    required: true,
                                    message: 'Field is required'
                                }
                            ]}>
                            <TextArea rows={3} placeholder="Address" key={orderInfo.address} />
                        </Form.Item>
                    </Col>

                </Row>
            </Form>
        </Modal >
    )
}

export default DeliveryModal