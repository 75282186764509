import { Link, useHistory } from "react-router-dom";
import { Avatar, Button, Dropdown, Layout, Menu, Select, Typography, message } from 'antd';
import { LoginOutlined, ShoppingCartOutlined, UserOutlined, DollarCircleOutlined, MoneyCollectOutlined, MobileOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import { getUser } from "../data";
import useWebSocket from "../utility/use-websocket";
import { useOnlineStatus } from "../hooks";

const { Header } = Layout;
const { Text } = Typography;
const { Option } = Select;

export default function MainHeader() {

    const { socketHandler } = useWebSocket()
    const isOnline = useOnlineStatus();

    let history = useHistory();
    let cashRegisterId = localStorage.getItem('cashRegister_id');
    let branch_id = parseInt(localStorage.getItem('posBranchId'))

    const [branch, setBranch] = useState({})
    const [selectedBranch, setSelectedBranch] = useState(branch_id);


    const [branches, setBranches] = useState([])

    const logout = () => {
        if (cashRegisterId) {
            message.error("Please close the cash register first.")
        } else {
            localStorage.removeItem('token');
            localStorage.removeItem('posBranchId');
            history.push({ pathname: '/' })
        }
    }

    //switch branch
    const handleBranchChange = (e) => {
        if (cashRegisterId) {
            message.error("Please close the cash register first.", 3)
        } else {
            if (e == branch_id) {
                message.error("You are already in this branch. Please try another branch.", 3)
            } else {
                localStorage.removeItem('posBranchId');
                localStorage.setItem('posBranchId', e);
                window.location.reload(true);
            }
        }
    }

    const loadBranches = async (data) => {
        const response = await getUser();
        if (response) {
            const user_branches = (response.user_branches).map(i => i.branch_id)
            const filteredArray = data.filter(item => user_branches.includes(item.id));
            setBranches(filteredArray)
            setBranch(filteredArray[0])
            localStorage.setItem('currency', response.user_store.currency);
        }
    }

    useEffect(() => {
        // branches
        socketHandler('branches', null, (response) => {
            loadBranches(response)
        });
    }, [])

    const profile = (
        <Menu style={{ padding: '10px' }}>
            <Menu.Item key="1" icon={<UserOutlined />}><Link to={'/profile'}>Profile</Link></Menu.Item>
            <Menu.Item key="2" onClick={logout} icon={<LoginOutlined />}>Logout</Menu.Item>
        </Menu>
    )

    return (
        <Header>
            <Menu mode="horizontal" style={{ background: '#003366', color: '#FFF' }} >
                <Menu.Item key="3" icon={<MobileOutlined />}><Link to={'/'}>Sales Screen</Link></Menu.Item>
                <Menu.Item key="4" icon={<DollarCircleOutlined />}><Link to={'/cash-register'}>Cash Register</Link></Menu.Item>
                <Menu.Item key="5" icon={<MoneyCollectOutlined />}><Link to={'/cash-reports'}>Cash Reports</Link></Menu.Item>
                <Menu.Item key="6" icon={<ShoppingCartOutlined />}><Link to={'/orders'}>Orders</Link></Menu.Item>
                <Menu.Item key="7">
                    <Dropdown overlay={profile} placement="bottom">
                        <a><Avatar icon={<UserOutlined />} /></a>
                    </Dropdown>
                </Menu.Item>

                <Menu.Item key='branches' style={{ cursor: 'not-allowed', pointerEvents: 'none' }} disabled>
                    <Text style={{ color: 'white' }}>{branch.name}</Text>
                </Menu.Item>

                <Menu.Item key='network-status' style={{ cursor: 'not-allowed', pointerEvents: 'none' }} disabled>
                    <Button type={isOnline ? "primary" : "danger"}>{isOnline ? "Online" : "Offline"}</Button>
                </Menu.Item>

                <Menu.Item key="8" style={{ marginLeft: 'auto', width: '20%' }}>
                    <Select
                        style={{ width: '70%', marginRight: 10 }}
                        defaultValue={selectedBranch}
                        key={selectedBranch}
                        onChange={setSelectedBranch}
                    >
                        {branches.map(({ id, name }) => (
                            <Option value={id} key={id}>
                                {name}
                            </Option>
                        ))}
                    </Select>
                    <Button onClick={() => handleBranchChange(selectedBranch)}>Switch</Button>
                </Menu.Item>

            </Menu>
        </Header >
    );
}