import { ajaxService, urlService } from "../services";
import { Response } from "../utility";

const setDependency = () => { ajaxService.setDependency() }

export async function getStoreSettings() {
    setDependency()
    return ajaxService.get(urlService.storeSettings).then(response => {
        return Response(response.data);
    })
}

export async function getStoreSetting(slug) {
    setDependency()
    return ajaxService.get(urlService.storeSettings + '/' + slug).then(response => {
        return Response(response.data);
    })
}
